import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableContainer, TableHead, TableRow, TablePagination, Paper, TextField, Button, IconButton } from '@mui/material';
import { StyledTableCell, StyledTableRow } from './StyledTableComponents';
import ServiceTableHeader from './ServiceTableHeader';
import EditIcon from '@mui/icons-material/Edit'; // Import Edit icon

const ServiceTable = React.memo(({
  data: initialData,
  columns,
  searchValue,
  editingRow,
  editedPrices,
  onEditPrice,
  onPriceChange,
  onSavePrice,
  priceFieldName 
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [filteredData, setFilteredData] = useState(initialData);

  useEffect(() => {
    setPage(0);
    setFilteredData(initialData); 
  }, [searchValue, initialData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handlePriceInputChange = (rowId, value) => {
    const newValue = value.replace(/[^\d.]/g, '').replace(/(\..*)\./g, '$1');
    onPriceChange(rowId, newValue);
  };

  const handleSavePrice = (rowId) => {
    let priceToSave = parseFloat(editedPrices[rowId].replace('$', ''));
    priceToSave = isNaN(priceToSave) ? '0.00' : priceToSave.toFixed(2);
    onSavePrice(rowId, priceToSave, priceFieldName); // Now passing the price field name
  };

  const formatCurrency = (value) => {
    if (typeof value === 'string' && value.startsWith('$')) {
      value = parseFloat(value.substring(1));
    } else {
      value = parseFloat(value);
    }

    if (!isNaN(value)) {
      return `$${value.toFixed(2)}`;
    } else {
      return '$0.00';
    }
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });

    const sortedData = [...filteredData].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
      return 0;
    });
    setFilteredData(sortedData);
  };

  
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <ServiceTableHeader
              columns={columns}
              requestSort={requestSort}
              sortConfig={sortConfig}
            />
          </TableHead>
          <TableBody>
            {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <StyledTableRow key={row._id}>
                {columns.map((column) => {
                  const isEditingPrice = editingRow === row._id && column.id === priceFieldName;
                  let displayValue = row[column.id];
                  if (column.id === 'isFireRated') {
                    displayValue = row.isFireRated ? 'Yes' : 'No'; 
                  }
                  if (column.id === priceFieldName && !editingRow) {
                    displayValue = formatCurrency(row[column.id]);
                  }
                  return (
                    <StyledTableCell key={column.id}>
                      {isEditingPrice ? (
                        <TextField
                          value={editedPrices[row._id] || ''}
                          onChange={(e) => handlePriceInputChange(row._id, e.target.value)}
                          size="small"
                          margin="none"
                          InputProps={{
                            startAdornment: <span>$</span>,
                          }}
                        />
                      ) : (
                        displayValue  
                      )}
                      {column.id === 'actions' ? (
                        editingRow === row._id ? (
                          <Button onClick={() => handleSavePrice(row._id)} size="small" variant="contained">
                            Save
                          </Button>
                        ) : (
                          <IconButton onClick={() => onEditPrice(row._id, row[priceFieldName], priceFieldName)} size="small">
                            <EditIcon sx={{ color: 'black !important' }} /> {/* Black color icon */}
                          </IconButton>
                        )
                      ) : null}
                    </StyledTableCell>
                  );
                })}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
});

export default ServiceTable;