import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
  DialogActions,
  Typography,
} from '@mui/material';

// Define a mapping for headers
const headerMapping = {
  firstname: 'Name',
  statusbit: 'Status',
};

const DynamicFilter = ({
  open,
  onClose,
  filters,
  filterHeader,
  applyFilters,
  onApply
}) => {
  const [selectedFilters, setSelectedFilters] = useState(() => {
    const initialFilters = {};
    filterHeader.forEach(category => {
      initialFilters[category.toLowerCase()] = new Set(); // Use lowercase keys
    });
    return initialFilters;
  });

  console.log("filterHeaders", filterHeader);

  const handleToggle = (category, value) => {
    const normalizedCategory = category.toLowerCase(); // Normalize category key

    setSelectedFilters(prevFilters => {
      const updatedFilters = { ...prevFilters };
      updatedFilters[normalizedCategory] = new Set(updatedFilters[normalizedCategory]);

      if (updatedFilters[normalizedCategory].has(value)) {
        updatedFilters[normalizedCategory].delete(value);
      } else {
        updatedFilters[normalizedCategory].add(value);
      }
      console.log('updatedFilters: ', updatedFilters);
      return updatedFilters;
    });
  };

  const handleSubmit = () => {
    onClose(); 
    onApply(selectedFilters); 
  };

  // Function to get the display label for each category
  const getDisplayLabel = (category) => {
    return headerMapping[category.toLowerCase()] || category;
  };

  return (
    <Dialog open={open} onClose={onClose}
      maxWidth="md"
      fullWidth 
      sx={{ '& .MuiDialog-paper': { width: '30%' } }}
    >
      <DialogTitle>Filter Users</DialogTitle>
      <DialogContent>
        {filterHeader?.map((category) => (
          <FormGroup key={category}>
            <Typography variant="subtitle1" fontWeight="bold">
              {getDisplayLabel(category)} {/* Use mapped or original category name */}
            </Typography>
            {filters[category.toLowerCase()]?.map((value) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedFilters[category.toLowerCase()] && selectedFilters[category.toLowerCase()].has(value)}
                    onChange={() => handleToggle(category, value)}
                  />
                }
                label={value}
                key={value}
              />
            ))}
          </FormGroup>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Apply</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DynamicFilter;
