import { Box, Typography, Button } from "@mui/material";
import React, { useEffect } from "react";
import BeardcrumNavigator from "../../components/BeardcrumNavigator";
import Sidebar from "../../components/Sidebar";
import { handleAddUserModal } from "../../features/login/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import OverView from "./OverView";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import UserInfo from "./UserInfo";
import Transaction from "./Transaction";
import { Link, useParams } from "react-router-dom";
import {
  resetCompanyDetail,
  singleCompanyDetail,
} from "../../features/companies/companySlice";
import Loading from "../../components/Loading";

const CompanyInfo = () => {
  const dispatch = useDispatch();
  const param = useParams();
  const { isDrawerOpen } = useSelector((store) => store.login);
  const { isLoading, companyDetail } = useSelector((store) => store.company);
  const [tab, setTab] = useState("overview");
  const breadcrumbs = [
    <Typography
      key="3"
      color="text.primary"
      style={{
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "34px",
        lineHeight: "36px",
        color: "#000000",
      }}
    >
      <Link
        to={"/companies"}
        style={{ textDecoration: "none", color: "black" }}
        onClick={() => {
          dispatch(resetCompanyDetail());
        }}
      >
        <ArrowBackIosRoundedIcon
          style={{ marginRight: "1rem", cursor: "pointer" }}
        />
      </Link>
      {companyDetail.data?.findCompany.companyName}
    </Typography>,
  ];
  const { lastUpdated } = useSelector((store) => store.login);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="page-section">
      <Sidebar />
      <Box
        className="page-content"
        sx={{
          width: isDrawerOpen ? `calc(100% - 240px)` : `calc(100% - 57px)`,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
          }}
        >
          <BeardcrumNavigator
            breadcrumbs={breadcrumbs ? breadcrumbs : "Beardcrums"}
          />

          {tab === "userInfo" ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => dispatch(handleAddUserModal())}
            >
              add user
            </Button>
          ) : (
            ""
          )}
        </Box>
        <Box component="div" className="tab_btns" sx={{ mb: 3 }}>
          <Button
            variant="outlined"
            size="large"
            className={tab === "overview" ? "active" : ""}
            onClick={() => setTab("overview")}
          >
            Overview
          </Button>
          <Button
            variant="outlined"
            size="large"
            className={tab === "userInfo" ? "active" : ""}
            onClick={() => setTab("userInfo")}
          >
            User Info
          </Button>
        </Box>

        {tab === "overview" ? <OverView data={companyDetail.data} /> : null}
        {tab === "userInfo" ? <UserInfo /> : null}
      </Box>
    </div>
  );
};

export default CompanyInfo;
