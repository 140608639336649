import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
  DialogActions,
  Typography,
} from '@mui/material';

const DynamicFilter = ({
  open,
  onClose,
  filters = {},
  filterHeader = [],
  onApply,
}) => {
  const [selectedFilters, setSelectedFilters] = useState(() => {
    const initialFilters = {};
    filterHeader.forEach(category => {
      initialFilters[category.toLowerCase()] = new Set();
    });
    return initialFilters;
  });

  const handleToggle = (category, value) => {
    setSelectedFilters(prevFilters => {
      const updatedFilters = { ...prevFilters };
      updatedFilters[category] = new Set(updatedFilters[category]);
      if (updatedFilters[category].has(value)) {
        updatedFilters[category].delete(value);
      } else {
        updatedFilters[category].add(value);
      }
      return updatedFilters;
    });
  };

  const handleSubmit = () => {
    onClose(); 
    console.log(selectedFilters , "selected filterssssssssssssssss in submit function")
    onApply(selectedFilters); 
  };
  const convertString = (input)=> {
    // Split the input string based on uppercase letters
    const splitWords = input.replace(/([A-Z])/g, ' $1').trim();
  
    // Capitalize the first letter of each word
    const capitalizedWords = splitWords
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  
    return capitalizedWords;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Filter Services</DialogTitle>
      <DialogContent>
        {filterHeader.map((category) => (
          <FormGroup key={category}>
            <Typography variant="subtitle1" fontWeight="bold">
              {convertString(category)}
            </Typography>
            {filters[category]?.map((value) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedFilters[category]?.has(value) || false}
                    onChange={() => handleToggle(category, value)}
                  />
                }
                label={value}
                key={value}
              />
            ))}
          </FormGroup>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Apply</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DynamicFilter;
