  import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
const initialState = {
    users: {},
    staff:{},
    isLoading: false,
    isStaffLoading: false,
    alert: false,
    responseStatus: '',
    responseMsg: '',
    isUpdated:false,
}

export const getUsers = createAsyncThunk(
  'userInfo/getUsers',
  async (id, thunkAPI) => {
    try {
      const resp = await axios(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/admin/staff/listing/${id}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        }
      });
      console.log('staff ::::::::::::::::D:D:D:D: ',resp.data);
      return resp.data;
    } catch (error) {
      // console.log(error.response);
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);

export const addUser = createAsyncThunk(
  "userInfo/addUser",
  async (values, thunkAPI) => {
    try {
      console.log('Adding user: ', values);
      let formData = new FormData();

      // Append all provided fields to formData except 'image'
      Object.keys(values).forEach(key => {
        if (key !== 'image') {
          formData.append(key, values[key]);
        }
      });

      let uploadedImageUrl;

      // Check if an image is provided and it's not null
      if (values.image) {
        console.log('Image present, uploading to storage service');
        // Append the image file to a new FormData object for upload
        let imageFormData = new FormData();
        imageFormData.append("image", values.image);

        // Upload the image to the storage service
        const imageUploadResp = await axios.post(
          `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/admin/staff/uploadimage`,
          imageFormData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        uploadedImageUrl = imageUploadResp.data.data.Location;
        console.log('Image uploaded: ', uploadedImageUrl);

        // Append the uploaded image URL to the original formData
        formData.append("image", uploadedImageUrl);
      } else {
        console.log('No image provided');
        formData.append("image", " ");
      }

      // Log formData contents
      for (let pair of formData.entries()) {
        console.log(pair[0] + ': ' + pair[1]);
      }

      // Send the formData to the backend to create a new user
      const resp = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/admin/staff/add`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      return resp.data;
    } catch (error) {
      const responseMessage = error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : 'Something went wrong';
      console.error('Error adding user:', responseMessage);
      return thunkAPI.rejectWithValue({ message: responseMessage });
    }
  }
);

export const editStaff = createAsyncThunk(
  "userInfo/editStaff",
  async (values, thunkAPI) => {
    try {
      console.log('Received values: ', values);
      let imageUpload = '';
      let formData = new FormData();

      // Append all provided fields to formData except 'image'
      Object.keys(values).forEach(key => {
        if (key !== 'image') {
          formData.append(key, values[key]);
        }
      });

      console.log(values.image , "imageeeeee");
      let uploadedImageUrl;

      // Check if an image is provided and it's not null
      if (values.image instanceof File) {
        console.log('image present');
        // Append the image file to a new FormData object for upload
        let imageFormData = new FormData();
        imageFormData.append("image", values.image);

        // Upload the image
        const imageUploadResp = await axios.post(
          `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/admin/staff/uploadimage`,
          imageFormData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        uploadedImageUrl = imageUploadResp.data.data.Location;
        console.log('image is uploading: ', uploadedImageUrl);
        // Append the uploaded image URL to the original formData
        imageUpload = uploadedImageUrl;
      } else if (typeof values.image === 'string') {
        // If values.image is a string (URL), preserve the existing image URL
        imageUpload = values.image;
      }

      console.log('appendappendappend', formData);
      formData.append("image", imageUpload);

      // Update the staff member's information
      const resp = await axios.put(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/admin/staff/update/${values.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      return resp.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue('Something went wrong');
    }
  }
);

export const staffDetail = createAsyncThunk(
  "userInfo/staffDetail",
  async (id, thunkAPI) => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/admin/staff/detail/${id}`,
        
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
     
      // console.log(resp);
      return resp.data;
    } catch (error) {
      console.log(error.response);
      // return "something went wrong";
      return error.response;
    }
  }
);


const userInfoSlice = createSlice({
    name: 'userInfo',
    initialState,
    reducers: {
      userInfoResponseClr: (state, action) => {
        state.responseMsg = "";
        state.responseStatus = "";
        state.alert = false;
      },
      resetStaffDetail: (state) => {
        state.staff = {};
  
      },
    },  
    extraReducers: {
        [getUsers.pending]: (state) => {
            state.isLoading = true;
        },
        [getUsers.fulfilled]: (state, action) => {
            state.users     = {}
            state.isLoading = false;
            state.users= action.payload;
        },
        [getUsers.rejected]: (state) => {
            state.isLoading = false;
        },
        [editStaff.pending]: (state) => {
          state.isLoading = true;
      },
      [editStaff.fulfilled]: (state, action) => {
          state.isUpdated = !state.isUpdated;

          const defaultMessage = '';
          const message = action.payload && (action.payload.message || (action.payload.data && action.payload.data.message)) ? action.payload.message || action.payload.data.message : defaultMessage;
      
          state.responseStatus = message !== defaultMessage ? "success" : "info";
          state.responseMsg = message;
          state.alert = true;
          state.isLoading = false;

      },
      [editStaff.rejected]: (state) => {
          state.isLoading = false;
      },
      
        [staffDetail.pending]: (state) => {
          state.isStaffLoading = true;
      },
      [staffDetail.fulfilled]: (state, action) => {
          state.staff     = {}
          state.isStaffLoading = false;
          state.staff = action.payload;
      },
      [staffDetail.rejected]: (state) => {
          state.isStaffLoading = false;
      },
        
        [addUser.pending]: (state) => {
            state.isLoading = true;
        },
        [addUser.fulfilled]: (state, action) => {
          // Initialize a default message in case `action.payload` or the message is not as expected
          const defaultMessage = '';
          // Safely attempt to read the message, falling back to the default if necessary
          const message = action.payload && (action.payload.message || (action.payload.data && action.payload.data.message)) ? action.payload.message || action.payload.data.message : defaultMessage;
          // Update state based on whether a meaningful message was provided
          state.responseStatus = message !== defaultMessage ? "success" : "info";
          state.responseMsg = message;
          state.alert = true;
          state.isLoading = false;
        },
   
        [addUser.rejected]: (state, action) => {
          // Use a similar pattern for rejected cases, providing a default error message
          const errorMessage = action.payload && action.payload.message ? action.payload.message : "An unexpected error occurred while processing your request.";
        
          state.isLoading = false;
          state.responseStatus = "error";
          state.responseMsg = errorMessage;
          state.alert = true;
        },
        
        
    }
})

export const { userInfoResponseClr , resetStaffDetail} = userInfoSlice.actions;
  
export default userInfoSlice.reducer;