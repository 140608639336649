import {
  Box,
  Table,
  TableContainer,
  Typography,
  IconButton,
  Button,
  Alert,
} from "@mui/material";
import React from "react";
import BeardcrumNavigator from "../../components/BeardcrumNavigator";
import Sidebar from "../../components/Sidebar";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import SearchBox from "../../components/SearchBox";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import { useDispatch, useSelector } from "react-redux";
import { handleAddAdminPortalUserModal, handleEditAdminPortalUserModal } from "../../features/login/loginSlice";
import AddAdminPortalUser from "./AddAdminPortalUser";
import EditAdminPortalUser from "./EditAdminPortalUser";
import { useState, useEffect } from "react";
import { adminPortalUserResponseClr, getPortalUser } from "../../features/admin-portal-user/adminPortalUserSlice";
import Loading from "../../components/Loading";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import CloseIcon from '@mui/icons-material/Close';
import { CSVLink } from "react-csv";
import moment from "moment";
import { useLocation, useNavigate } from 'react-router-dom';
import FilterListIcon from '@mui/icons-material/FilterList';
import DynamicFilter from "./DynamicFilter";

const columns = [
  { id: "firstName", label: "Name", minWidth: 100, fontWeight: '600' },
  { id: "email", label: "Email", minWidth: 100, fontWeight: '600' },
  { id: "userType", label: "Role", minWidth: 100, fontWeight: '600' },
  { id: "statusBit", label: "Status", minWidth: 150, fontWeight: '600' },
  { id: "updatedAt", label: "Last Active", minWidth: 150, fontWeight: '600' },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.gray,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
    backgroundColor: "#ddd",
  },
}));

const AdminPortalUser = () => {
  const { isDrawerOpen, isAddAdminPortalUserModal, isEditAdminPortalUserModal } = useSelector((store) => store.login);
  const { portalUsers, isLoading, responseStatus, responseMsg, alert } = useSelector((store) => store.adminPortalUser);
  const dispatch = useDispatch();
  const location = useLocation();
  const  navigate = useNavigate()
  const userDataFromState = location.state?.userData;
  const openModal = location.state?.openModal || false;
  
  const [filterModalOpen, setFilterModalOpen] = React.useState(false);
  const [filters, setFilters] = React.useState({
    firstName: [],

    statusBit: [],
  });

  // Define function to update filters and apply them together with search
  const applyFilters = (selectedFilters) => {
    console.log('Applying filters:', selectedFilters);
    setFilters(selectedFilters);
    //updateFilteredProjects(searchValue, selectedFilters); // no need since there is useEffect hook already for this (241 line)
  };

  
  const breadcrumbs = [
    <Typography
      key="3"
      color="text.primary"
      style={{
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "34px",
        lineHeight: "36px",
        color: "#000000",
      }}
    >
      Admin Portal Users
    </Typography>,
  ];

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchValue, setSearchValue] = React.useState('');
  const [alertDialog, setAlertDialog] = React.useState(false);
  const [sortDirection, setSortDirection] = React.useState('desc'); // 'asc' for ascending, 'desc' for descending
  const [sortedAdminUsers, setsortedAdminUsers] = React.useState([]);
  const [loading, setLoading] = useState(true);
  const [openModalState, setOpenModalState] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearch = (searchedValue) => {
    setSearchValue(searchedValue);
    setPage(0);
  };

  const sortLastLogin = () => {
    // Determine the next sort direction beforehand
    const nextSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';

    const sortedData = [...(sortedAdminUsers.length > 0 ? sortedAdminUsers : portalUsers?.data)].sort((a, b) => {
      const dateA = new Date(a?.updatedAt);
      const dateB = new Date(b?.updatedAt);

      return nextSortDirection === 'asc' ? dateA - dateB : dateB - dateA;
    });
    

    // Now update the sort direction and the sorted users list
    setSortDirection(nextSortDirection);
    setsortedAdminUsers(sortedData);
  };

  useEffect(() => {
    dispatch(getPortalUser()).finally(() => setLoading(false));
  }, [dispatch, isAddAdminPortalUserModal]);

  useEffect(() => {
    if (!loading) {
      if (userDataFromState && openModal) {
        dispatch(handleEditAdminPortalUserModal(userDataFromState));
        // Clear location state
        navigate(location.pathname, { replace: true, state: { ...location.state, openModal: false } });
      }
    }
  }, [loading, userDataFromState, openModal, dispatch, navigate, location.pathname, location.state]);

  useEffect(() => {
    if (portalUsers?.data && !sortedAdminUsers.length) {
      setsortedAdminUsers(portalUsers.data);
    }
  }, [portalUsers?.data]);

/// useeeee
  useEffect(() => {
    if (portalUsers?.data) {
      let filteredUsers = [...portalUsers.data];
  
      // Apply filters
      if (filters?.firstname?.size > 0) {
        filteredUsers = filteredUsers.filter(user => filters.firstname.has(user.firstName));
      }
  
   
  
      if (filters?.statusbit?.size > 0) {
        filteredUsers = filteredUsers.filter(user => (user.statusBit ? 'enabled' : 'disabled') === [...filters.statusbit][0]);
      }
  
      setsortedAdminUsers(filteredUsers);
    }
  }, [filters, portalUsers?.data]);
  
  

  useEffect(() => {
    dispatch(getPortalUser());
  }, [isAddAdminPortalUserModal]);
  
  useEffect(() => {
    setAlertDialog(alert);
  }, [alert]);
  
  const SortButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  }));

  const getCsvData = () => {
    if (!columns) {
      return [];
    }
  
    return sortedAdminUsers
      ?.filter((data) =>
        data.firstName.toLowerCase().includes(searchValue) ||
        data.email.toLowerCase().includes(searchValue)
      )
      ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((row) => {
        const csvRow = {};
        console.log("csv file ", csvRow)
        columns.forEach((column) => {
          const value = row[column.id];
  
          if (column.id === 'firstName') {
            csvRow['Name'] = value || 'N/A';
          } else if (column.id === 'email') {
            csvRow['Email'] = value || 'N/A';
          } else if (column.id === 'userType') {
            csvRow['Role'] = value
              ? value.charAt(0).toUpperCase() + value.slice(1)
              : 'N/A';
          } else if (typeof value === 'boolean') {
            csvRow['Status'] = value === true ? 'Enabled' : 'Disabled';
          } else if (column.id === 'updatedAt') {
            csvRow['Last Active'] = value
              ? moment(value).format('MM/DD/YY hh:mm:ss A')
              : 'N/A';
          }
        });
        return csvRow;
      });
  };
  
  

  const handleRowClick = (userData) => {
    dispatch(handleEditAdminPortalUserModal(userData));
  };

  if (isLoading || !sortedAdminUsers) {
    return <Loading />;
  }

  return (
    <div className="page-section">
      <Sidebar />
      <Box
        className="page-content"
        sx={{
          width: isDrawerOpen ? `calc(100% - 240px)` : `calc(100% - 57px)`, overflow: "hidden"
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
          }}
        >
          <BeardcrumNavigator
            breadcrumbs={breadcrumbs ? breadcrumbs : "Beardcrums"}
          />
          <Box
            component="div"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <CSVLink 
              data={getCsvData() || ""}
              filename="admin_portal_user.csv"
            >
              <Button
                variant="outlined"
                className="bc-btn-outline"
                color="primary"
              >
                Export csv
              </Button>
            </CSVLink>
            <Button
              variant="contained"
              // className="bc-btn-outline"
              sx={{padding : '9px 15px'}}
              color="primary"
              onClick={() => dispatch(handleAddAdminPortalUserModal())}
            >
              Add User
            </Button>
          </Box>
        </Box>
        <Box
              component='div'
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
                float: 'right',
              }}
            >
              <Button
                variant='outlined'
                className='bc-btn-outline'
                color='primary'
                onClick={() => setFilterModalOpen(true)}
              >
                Filter
              </Button>
            </Box>
        <Box
          component="div"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Box component="div" sx={{
              marginLeft: '2%'
            }}>
            <Box
              sx={{
                background: '#FFFFFF',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                borderRadius: '33px',
                height: 50,
                display: 'flex',
                alignItems: 'center',
                maxWidth: "208px",
                maxHeight: '30px',
                border: '1px solid #ddd',
                overflow: 'hidden'
              }}
            >
              <SearchRoundedIcon sx={{ width: '16%', marginLeft: '6px' }} />
              <input type="text" value={searchValue} placeholder='Search' className='search-input' onChange={(e) => handleSearch(e.target.value)} />
            </Box>
          </Box>
        </Box>
        <Box sx={{ width: '100%' }}>
          {alert ? (
            <Alert
              severity={responseStatus}
              color={responseStatus}
              sx={{ mb: 3, width: '100%' }}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    dispatch(adminPortalUserResponseClr(false));
                    setAlertDialog(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >{responseMsg}</Alert>
          ) : null}
        </Box>
        <Paper sx={{ width: "80%", overflow: "hidden" }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <StyledTableRow>
                  {columns.map((column) => (
                    <StyledTableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, fontWeight: column.fontWeight }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: column.id === "updatedAt" ? 'space-between' : 'flex-start',
                          alignItems: 'center',
                        }}
                      >
                        {column.label}
                        {column.id === "updatedAt" && (
                          <IconButton
                            onClick={sortLastLogin}
                            aria-label="sort"
                            size="small"
                            sx={{ ml: 1 }}
                          >
                            <ArrowDownward />
                          </IconButton>
                        )}
                      </Box>
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              </TableHead>
              <TableBody>
  {sortedAdminUsers
    ?.filter((data) => {
      const status = data.statusBit ? 'enabled' : 'disabled';
      return (
        data.firstName.toLowerCase().includes(searchValue.toLowerCase()) || 
        data.email.toLowerCase().includes(searchValue.toLowerCase()) ||
        status.includes(searchValue.toLowerCase())
      );
    })
    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((row) => (
      <StyledTableRow hover role="checkbox" tabIndex={-1} key={row.id}>
        {columns.map((column) => {
          const value = row[column.id];
          return (
            <StyledTableCell key={column.id} align={column.align}>
              {column.id === 'email' || column.id === 'firstName' ? (
                <Typography className="tableLink" onClick={() => handleRowClick(row)}>
                  {value}
                </Typography>
              ) : column.id === 'userType' ? (
                <Typography>{value.charAt(0).toUpperCase() + value.slice(1)}</Typography>
              ) : typeof value === 'boolean' ? (
                value === true ? 'Enabled' : 'Disabled'
              ) : column.id === 'updatedAt' ? (
                moment(value).format('MM/DD/YY hh:mm:ss A')
              ) : (
                value
              )}
            </StyledTableCell>
          );
        })}
      </StyledTableRow>
    ))}
    {console.log('sortedAdminUserssortedAdminUsers ',sortedAdminUsers.length)}
  {sortedAdminUsers.length === 0 && (
    <StyledTableRow>
      <StyledTableCell colSpan={columns.length} align="center">
        No results found
      </StyledTableCell>
    </StyledTableRow>
  )}
</TableBody>


            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={portalUsers.data ? portalUsers.data.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      {isAddAdminPortalUserModal && <AddAdminPortalUser />}
      {isEditAdminPortalUserModal && <EditAdminPortalUser />}
      <DynamicFilter
      open={filterModalOpen}
        onClose={() => setFilterModalOpen(false)}
        filters={{
          firstname:[
            ...new Set(
              portalUsers?.data?.map((user) => user?.firstName)
            ),
          ],
         
          statusbit:[
            ...new Set(portalUsers?.data?.map((user) => user?.statusBit ? 'enabled' : 'disabled')),
          ],
          
        }}
        filterHeader={["firstname",  "statusbit"]}
        applyFilters={applyFilters}
        onApply={applyFilters}
      />
    </div>
  );
};

export default AdminPortalUser;