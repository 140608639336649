import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { handleAddCompanyModal, setIsDrawerOpen } from "../../features/login/loginSlice";
import {
  Container,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Box,
  Checkbox,
  IconButton,
} from "@mui/material";
import ModeEditOutlineRoundedIcon from "@mui/icons-material/ModeEditOutlineRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useRef } from "react";
import { Field, FieldArray, Form, Formik, useFormikContext } from "formik";
import { useState } from "react";
import { object } from "yup";
import * as Yup from "yup";
import { useEffect } from "react";
import { getServices } from "../../features/services/serviceSlice";
import {
  addCompany,
  getCompanies,
} from "../../features/companies/companySlice";
import { useNavigate } from "react-router-dom";
const AddCompany = (props) => {
  const { isAddCompanyModal } = useSelector((store) => store.login);
  const { services } = useSelector((store) => store.service);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = React.useState("enable");
  const [windowChecked, setWindowChecked] = React.useState(false);
  const [slidingGlassDoorChecked, setSlidingGlassDoorChecked] =
    React.useState(false);
  const [interiorDoorChecked, setInteriorDoorChecked] = React.useState(false);
  const states = [
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
  ];

  
  const [distance, setDistance] = React.useState("100 miles");
  const navigate = useNavigate();
  // const { values } = useFormikContext();

  const handleChange = (event) => {
    setStatus(event.target.value);
  };
  let imgInput = useRef(null);
  let imgRef = useRef(null);
  const pickFile = () => {
    imgInput.current.click();
  };
  const handleAddImg = (e) => {
    let file = e.target.files[0];
    let url = URL.createObjectURL(file);
    imgRef.current.src = url;
  
  };
  const clearImgRef = () => {
    imgRef.current.src = "/images/circle-gray.png";

  };

  const initialValues = {
    image: {},
    companyStatus: status,
    companyName: "",
    distanceWillingTravel: distance,
    services: [],
  };
  // program to convert first letter of a string to uppercase
  function capitalizeFirstLetter(str) {
    // converting first letter to uppercase
    const capitalized = str.charAt(0).toUpperCase() + str.slice(1);

    return capitalized;
  }
  useEffect(() => {
    dispatch(getServices());
  }, []);



  return (
    <Dialog
      open={isAddCompanyModal}
      scroll="body"
      onClose={() => dispatch(handleAddCompanyModal())}
      aria-describedby="scroll-dialog-description"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, formikHelpers) => {
          if (imgInput.current.files[0]) {
            values.image = imgInput.current.files[0];
          }
          try {
            await dispatch(addCompany(values)).unwrap(); 
      
            await dispatch(getCompanies());
      
            dispatch(handleAddCompanyModal());
          } catch (error) {
            console.error("Error adding company: ", error);
          }
 
        }}
        validationSchema={object({
          companyName: Yup.string()
            .test(
              "contains-only-spaces",
              "Input cannot be left blank",
              (value) => {
                if (!value) {
                  // if value is empty or undefined, allow it
                  return true;
                }
                return /^\s*$/.test(value) === false;
              }
            )
            .required("Please enter the company name"),
          city: Yup.string()
            .test(
              "contains-only-spaces",
              "Input cannot be left blank",
              (value) => {
                if (!value) {
                  // if value is empty or undefined, allow it
                  return true;
                }
                return /^\s*$/.test(value) === false;
              }
            )
            .required("Please enter the city name"),
          zip: Yup.string()
            .test(
              "contains-only-spaces",
              "Input cannot be left blank",
              (value) => {
                if (!value) {
                  // if value is empty or undefined, allow it
                  return true;
                }
                return /^\s*$/.test(value) === false;
              }
            )
            .required("Please enter the zip name"),
          representativeName: Yup.string()
            .test(
              "contains-only-spaces",
              "Input cannot be left blank",
              (value) => {
                if (!value) {
                  // if value is empty or undefined, allow it
                  return true;
                }
                return /^\s*$/.test(value) === false;
              }
            )
            .required("Please enter the representative name"),
          representativeEmail: Yup.string()
            .required("Please enter a contact email address")
            .email("Please write the valid email address"),

          addressOne: Yup.string()
            .test(
              "contains-only-spaces",
              "Input cannot be left blank",
              (value) => {
                if (!value) {
                  // if value is empty or undefined, allow it
                  return true;
                }
                return /^\s*$/.test(value) === false;
              }
            )
            .required("Please enter the company address"),

          representativeNumber: Yup.string()
            .matches(
              /^[2-9]{1}[0-9]{9}$/,
              "Please enter a contact phone number"
            )
            .required("Please enter a contact phone number"),
          services: Yup.array().min(1, "Select at least one service"),
        })}
      >
        {({ errors, touched, isValid, dirty, values }) => (
          <Form>
            <DialogTitle
              id="scroll-dialog-title"
              sx={{
                p: 1,
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "24px",
                lineHeight: "24px",
                letterSpacing: "0.18px",
                color: "#000000",
              }}
            >
              Add Company Details
            </DialogTitle>
            <Box
              component="div"
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box component="div" className="addImg">
                <img
                  src="/images/circle-gray.png"
                  alt="addCompanyImg"
                  ref={imgRef}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
                <input
                  type="file"
                  hidden
                  id="getImg"
                  ref={imgInput}
                  onChange={(e) => handleAddImg(e)}
                />
              </Box>
              <Box component="div">
                <IconButton onClick={pickFile}>
                  <ModeEditOutlineRoundedIcon style={{ color: "black" }} />
                </IconButton>
                <IconButton onClick={clearImgRef}>
                  <CloseRoundedIcon style={{ color: "black" }} />
                </IconButton>
              </Box>
            </Box>
            <DialogContent
              sx={{
                p: 0,
              }}
            >
              <DialogContentText
                component={"div"}
                id="scroll-dialog-description"
                tabIndex={-1}
              >
                <Container maxWidth="xs">
                  <FormControl
                    fullWidth
                    sx={{
                      mt: 4,
                      mb: 4,
                    }}
                  >
                    <InputLabel id="companyStatus">Company status</InputLabel>
                    <Field
                      as={Select}
                      labelId="companyStatus"
                      id="companyStatus"
                      name="companyStatus"
                      label="Company status"
                      error={
                        Boolean(errors.companyStatus) &&
                        Boolean(touched.companyStatus)
                      }
                      helperText={
                        Boolean(touched.companyStatus) && errors.companyStatus
                      }
                    >
                      <MenuItem value="enable">Enabled</MenuItem>
                      <MenuItem value="disable">Disabled</MenuItem>
                      <MenuItem value="pending">Pending</MenuItem>
                    </Field>
                  </FormControl>
                  <Field
                    as={TextField}
                    sx={{ width: "100%", mb: 4 }}
                    id="companyName"
                    name="companyName"
                    label="Company Name"
                    variant="outlined"
                    error={
                      Boolean(errors.companyName) &&
                      Boolean(touched.companyName)
                    }
                    helperText={
                      Boolean(touched.companyName) && errors.companyName
                    }
                  />
                  <Field
                    as={TextField}
                    sx={{ width: "100%", mb: 4 }}
                    id="addressOne"
                    name="addressOne"
                    label="Address Line 1"
                    variant="outlined"
                    error={
                      Boolean(errors.addressOne) && Boolean(touched.addressOne)
                    }
                    helperText={
                      Boolean(touched.addressOne) && errors.addressOne
                    }
                  />
                  <Field
                    as={TextField}
                    sx={{ width: "100%", mb: 4 }}
                    id="addressTwo"
                    name="addressTwo"
                    label="Address Line 2"
                    variant="outlined"
                    error={
                      Boolean(errors.addressTwo) && Boolean(touched.addressTwo)
                    }
                    helperText={
                      Boolean(touched.addressTwo) && errors.addressTwo
                    }
                  />
                  <Field
                    as={TextField}
                    sx={{ width: "100%", mb: 4 }}
                    id="city"
                    name="city"
                    label="City"
                    variant="outlined"
                    error={Boolean(errors.city) && Boolean(touched.city)}
                    helperText={Boolean(touched.city) && errors.city}
                  />
                  <FormControl fullWidth sx={{ mb: 4 }}>
                    <InputLabel id="state">State</InputLabel>
                    <Field
                      as={Select}
                      labelId="state"
                      id="state"
                      name="state"
                      label="State"
                      error={Boolean(errors.state) && Boolean(touched.state)}
                      helperText={Boolean(touched.state) && errors.state}
                    >
                      {states.map((state) => (
                        <MenuItem key={state} value={state}>
                          {state}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                  <Field
                    as={TextField}
                    sx={{ width: "100%", mb: 4 }}
                    id="zip"
                    name="zip"
                    label="Zip"
                    variant="outlined"
                    error={Boolean(errors.zip) && Boolean(touched.zip)}
                    helperText={Boolean(touched.zip) && errors.zip}
                  />

                  <FormControl fullWidth sx={{ mb: 4 }}>
                    <InputLabel id="distanceWillingTravel">
                      Distance willing to travel (in miles)
                    </InputLabel>
                    <Field
                      as={Select}
                      labelId="distanceWillingTravel"
                      id="distanceWillingTravel"
                      name="distanceWillingTravel"
                      label="Distance willing to travel (in miles)"
                      error={
                        Boolean(errors.distanceWillingTravel) &&
                        Boolean(touched.distanceWillingTravel)
                      }
                      helperText={
                        Boolean(touched.distanceWillingTravel) &&
                        errors.distanceWillingTravel
                      }
                    >
                      <MenuItem value="100 miles">100 miles</MenuItem>
                      <MenuItem value="200 miles">200 miles</MenuItem>
                    </Field>
                  </FormControl>
                  <Field
                    as={TextField}
                    sx={{ width: "100%", mb: 4 }}
                    id="representativeName"
                    name="representativeName"
                    label="Representative Name"
                    variant="outlined"
                    error={
                      Boolean(errors.representativeName) &&
                      Boolean(touched.representativeName)
                    }
                    helperText={
                      Boolean(touched.representativeName) &&
                      errors.representativeName
                    }
                  />
                  <Field
                    as={TextField}
                    sx={{ width: "100%", mb: 4 }}
                    id="representativeNumber"
                    name="representativeNumber"
                    label="Representative Number"
                    variant="outlined"
                    error={
                      Boolean(errors.representativeNumber) &&
                      Boolean(touched.representativeNumber)
                    }
                    helperText={
                      Boolean(touched.representativeNumber) &&
                      errors.representativeNumber
                    }
                  />
                  <Field
                    as={TextField}
                    sx={{ width: "100%", mb: 4 }}
                    id="representativeEmail"
                    name="representativeEmail"
                    label="Representative Email"
                    variant="outlined"
                    error={
                      Boolean(errors.representativeEmail) &&
                      Boolean(touched.representativeEmail)
                    }
                    helperText={
                      Boolean(touched.representativeEmail) &&
                      errors.representativeEmail
                    }
                  />
                  <Box sx={{ pl: 5 }}>
                    <Typography
                      sx={{
                        fontSize: "17px",
                        fontWeight: "bold",
                        textTransform: "capitalize",
                        color: "#000000",
                        textDecoration: "underline",
                      }}
                    >
                      Services Available
                    </Typography>

                    <Box>
                      {services.data?.map((service) => {
                        return (
                          <Field
                            as={FormControlLabel}
                            key={service.id}
                            name={`services`}
                            value={service.name}
                            label={capitalizeFirstLetter(service.name) + ":"}
                            labelPlacement="start"
                            sx={{
                              color: "#000000",
                              display: "flex",
                              justifyContent: "space-between",
                              width: "250px",
                              "& .MuiFormControlLabel-label": {
                                // Target the label within the FormControlLabel component
                                fontWeight: "bold", // This will make the label text bold
                              },
                            }}
                            control={<Checkbox color="black" />}
                            error={
                              Boolean(errors.services) &&
                              Boolean(touched.services)
                            }
                            helperText={
                              Boolean(touched.services) && errors.services
                            }
                          />
                        );
                      })}
                    </Box>
                  </Box>
                </Container>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                onClick={() => dispatch(handleAddCompanyModal())}
              >
                Cancel
              </Button>
              {/* <Button variant='contained' onClick={() => dispatch(handleAddCompanyModal())}>Save</Button>  */}
              <Button
                disabled={
                    !dirty ||
                    !isValid ||
                    !values.companyName ||
                    !values.city ||
                    !values.state ||
                    !values.zip ||
                    !values.representativeNumber ||
                    values.representativeNumber.length !== 10
                }
                variant="contained"
                type="submit"
                >
                Save
                </Button>

            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AddCompany;
