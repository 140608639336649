import { Box, Table, TableContainer, Typography, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import BeardcrumNavigator from '../../components/BeardcrumNavigator';
import Sidebar from '../../components/Sidebar';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import DeleteModal from '../../components/DeleteModal';
import Loading from '../../components/Loading';
import { getCoupons } from '../../features/coupons/couponsSlice';

const columns = [
  { id: "name", label: "Name", minWidth: 150, fontWeight: '600' },
  { id: "description", label: "Description", minWidth: 300, fontWeight: '600' },
  { id: "service", label: "Service", minWidth: 100, fontWeight: '600', textTransform: 'capitalize' },
  { id: "couponCode", label: "Code", minWidth: 150, fontWeight: '600' },
  { id: "statusBit", label: "Enabled", minWidth: 80, fontWeight: '600', textTransform: 'capitalize' },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.gray,
    color: theme.palette.common.black,
    borderRight: '1px solid #ddd'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderRight: '1px solid #ddd'
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
    backgroundColor: '#ddd',
  },
}));

const Coupons = () => {
  const dispatch = useDispatch();
  const { isDrawerOpen, isDeleteModal } = useSelector((store) => store.login);
  const { list, isLoading } = useSelector((store) => store.coupon);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state?.newCouponAdded) {
      dispatch(getCoupons());
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [dispatch, location, navigate]);

  const breadcrumbs = [
    <Typography key="3" color="text.primary" style={{
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '34px',
      lineHeight: '36px',
      color: '#000000'
    }}>
      Coupons
    </Typography>
  ];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    dispatch(getCoupons());
  }, [dispatch]);

  if (isLoading) {
    return (
      <Loading />
    )
  }
  console.log(columns , "columnsss")

  return (
    <div className="page-section">
      <Sidebar />
      <Box className="page-content" sx={{ width: isDrawerOpen ? `calc(100% - 240px)` : `calc(100% - 57px)`, overflow: "hidden" }}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3
        }}>
          <BeardcrumNavigator breadcrumbs={breadcrumbs ? breadcrumbs : "Beardcrums"} />
          <Box component="div" sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem'
          }}>
            <Button variant="contained" className="bc-btn-outline" color="primary" onClick={() => navigate('addCoupon')} >Add Coupon</Button>
          </Box>
        </Box>
        <Paper sx={{ width: '90%', overflow: 'hidden', margin: 'auto' }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table" sx={{}}>
              <TableHead>
                <StyledTableRow>
                  {columns.map((column) => (
                    <StyledTableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, fontWeight: column.fontWeight, textAlign: 'center' }}
                    >
                      {column.label}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {list.data
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row) => {
                    return (
                      <StyledTableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <StyledTableCell key={column.id} align={column.align} style={{ textTransform: column.textTransform , textAlign:`${column.label === "Service" || column.label === "Enabled"  ? "center" :'left'}`}}>
                              {typeof value === 'boolean' ? (value === true ? 'Y' : 'N') : column.id === 'name' ? (
                                <Link to={`/coupons/editCoupon/` + row.id} className='tableLink'>  <p className='tableLink'>{value}</p></Link>
                              ) : value}
                            </StyledTableCell>
                          );
                        })}
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={list.data ? list.data.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>

      {isDeleteModal ? (
        <DeleteModal />
      ) : null}
    </div>
  )
}

export default Coupons;
