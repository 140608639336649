import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import {
	Button,
	TextField,
	Table,
	TableContainer,
	Typography,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from "@mui/material";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import { object } from "yup";
import * as Yup from "yup";
import { refundTransaction } from "../../features/projects/projectSlice";
import { Buffer } from "buffer";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		color: theme.palette.common.black,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}));

const StyledPopupTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
		fontWeight: "bold",
	}

}));

const StyledTableRow = styled(TableRow)(({ theme, bgcolor }) => ({
	backgroundColor: bgcolor,
	"&:last-of-type": {
		backgroundColor: "#f0f0f0", // Set this only if it's the last row
	},
}));

const RefundContainer = styled(TableContainer)(({ theme }) => ({
	padding: "1rem",
	display: "flex",
	flexDirection: "column",
	gap: "1rem",
	[theme.breakpoints.down("md")]: {
		width: "100%",
	},
	[theme.breakpoints.up("md")]: {
		width: "475px",
	},
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
	height: "54px",
	marginBottom: "7rem",
	[theme.breakpoints.down("md")]: {
		width: "100%",
	},
	[theme.breakpoints.up("md")]: {
		width: "304px",
	},
}));

const mongoIDtoShortID = (mongoID) => {
	const buffer = Buffer.from(mongoID, "hex");
	let base64ID = buffer.toString("base64");

	base64ID = base64ID.replace(/\+/g, "-").replace(/\//g, "_").substring(0, 20);

	return base64ID;
};

const ProjectCustomersTable = ({ transactionType }) => {
	const { projectDetail } = useSelector((store) => store.project);

	const filteredTransactions = projectDetail?.data?.transactions?.filter(
		(transaction) => transaction.transactionType === transactionType
	);
	const filteredRefunds = projectDetail?.data?.refunds?.filter(
		(refund) => refund.transactionType === transactionType
	);
	const filterAmount = {
		'Initial Payment': projectDetail.data?.order_info.firstPaymentAmount.toFixed(2),
		'Verification & Prep Work': projectDetail.data?.order_info.secondPaymentAmount.toFixed(2),
		'Completion': projectDetail.data?.order_info.thirdPaymentAmount.toFixed(2)
	}

	const param = useParams();
	const dispatch = useDispatch();
	const [isRefund, setIsRefund] = useState(false);
	const shortID = mongoIDtoShortID(param.projectid);

	const handleRefundModal = () => {
		setIsRefund(!isRefund);
	};

	const initialValues = {
		refundAmount: parseInt(0).toFixed(2),
		reason: "requested_by_customer",
	};

	return (
		<>
			<Paper sx={{ width: transactionType === 'cost-tracking' ? "84%" : "100%", overflow: "hidden", mb: 3 }}>
				<TableContainer>
					<Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }}>
						<TableHead>
							<StyledTableRow>
								<StyledTableCell sx={{ width: '23%', fontWeight: "bold" }}>Transaction Type</StyledTableCell>
								<StyledTableCell sx={{ width: '15%', fontWeight: "bold", textAlign: 'center' }}>
									{transactionType === "cost-tracking" ? "Logged" : "Processed"}
								</StyledTableCell>
								<StyledTableCell sx={{ width: '20%', fontWeight: "bold", textAlign: 'center' }}>
									{transactionType === "contractor" ? "Paid To" : "Coupon Code"}
								</StyledTableCell>
								<StyledTableCell sx={{ width: '15%', fontWeight: "bold", textAlign: 'center' }}>Coupon Value</StyledTableCell>
								<StyledTableCell sx={{ width: '15%', fontWeight: "bold", textAlign: 'center' }}>Amount</StyledTableCell>
								{transactionType !== 'cost-tracking' && (
									<>
										<StyledTableCell sx={{ width: '10%', fontWeight: "bold", textAlign: 'center' }}>Status</StyledTableCell>
										<StyledTableCell sx={{ width: '10%', fontWeight: "bold", textAlign: 'end' }}>Action</StyledTableCell>
									</>
								)}
							</StyledTableRow>
						</TableHead>
						<TableBody>
							{filteredTransactions?.map((transaction, index) => (
								<StyledTableRow key={index} style={{ backgroundColor: index % 2 === 0 ? "white" : "#F7F7F7", color: 'black' }}>
									<StyledTableCell style={{ fontWeight: 600, width: '15%' }}>
										{transaction.transactionType}
									</StyledTableCell>
									<StyledTableCell style={{ fontWeight: 600, textAlign: 'center', width: '15%' }}>
										{moment(transaction.actualPaymentDate).format("MM/DD/YY")}
									</StyledTableCell>
									<StyledTableCell style={{ fontWeight: 600, textAlign: 'center', width: '20%' }}>
										{transactionType === "contractor" ? transaction.paidTo || "--" : projectDetail.data?.order_info.discountCode || "--"}
									</StyledTableCell>
									<StyledTableCell style={{ fontWeight: 600, textAlign: 'center', width: '15%' }}>
										{"$" + projectDetail.data?.order_info.discountAmount.toFixed(2)}
									</StyledTableCell>
									<StyledTableCell style={{ fontWeight: 600, textAlign: 'center', width: '15%' }}>
										{"$" + transaction.amount.toFixed(2)}
									</StyledTableCell>
									{transactionType !== 'cost-tracking' && (
										<>
											<StyledTableCell style={{ fontWeight: 600, textAlign: 'center', width: '10%' }}>
												{transaction.paymentStatus}
											</StyledTableCell>
											<StyledTableCell style={{ textAlign: 'end', paddingRight: '10px', width: '10%' }}>
												<Button variant="contained" style={{ background: '#00639B', borderRadius: '100px' }} onClick={handleRefundModal}>
													Refund
												</Button>
											</StyledTableCell>
										</>
									)}
								</StyledTableRow>
							))}
							{filteredRefunds?.map((refund, index) => (
								<StyledTableRow key={index} style={{ backgroundColor: index % 2 === 0 ? "white" : "#F7F7F7", color: 'black' }}>
									<StyledTableCell style={{ fontWeight: 600, width: '15%' }}>
										{refund.transactionType}
									</StyledTableCell>
									<StyledTableCell style={{ fontWeight: 600, textAlign: 'center', width: '15%' }}>
										{moment(refund.refundDate).format("MM/DD/YY")}
									</StyledTableCell>
									<StyledTableCell style={{ fontWeight: 600, textAlign: 'center', width: '20%' }}>
										{"--"}
									</StyledTableCell>
									<StyledTableCell style={{ fontWeight: 600, textAlign: 'center', width: '15%' }}>
										{"--"}
									</StyledTableCell>
									<StyledTableCell style={{ fontWeight: 600, textAlign: 'center', width: '15%' }}>
										{"$" + refund.amount.toFixed(2)}
									</StyledTableCell>
									{transactionType !== 'cost-tracking' && (
										<>
											<StyledTableCell style={{ fontWeight: 600, textAlign: 'center', width: '10%' }}>
												{refund.paymentStatus}
											</StyledTableCell>
											{/* <StyledTableCell style={{ textAlign: 'end', paddingRight: '10px', width: '10%' }}>
												<Button variant="contained" style={{ background: '#00639B', borderRadius: '100px' }} onClick={handleRefundModal}>
													Refund
												</Button>
											</StyledTableCell> */}
										</>
									)}
								</StyledTableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>


			{isRefund ? (
				<Dialog
					open={isRefund}
					scroll="body"
					onClose={handleRefundModal}
					aria-describedby="scroll-dialog-description"
				>
					<DialogTitle
						id="scroll-dialog-title"
						sx={{
							p: 1,
							fontStyle: "normal",
							fontWeight: "400",
							fontSize: "24px",
							lineHeight: "24px",
							letterSpacing: "0.18px",
							color: "#000000",
						}}
					>
						Refund
					</DialogTitle>
					<RefundContainer>
						<Typography
							sx={{
								fontWeight: "600",
								fontSize: "14px",
								color: "#000000",
							}}
						>
							{projectDetail.data?.order_detail.length > 0 &&
								projectDetail.data?.order_detail[0].service
								? projectDetail.data?.order_detail[0].service.name
								: ""}{" "}
							{projectDetail?.data?.order_info?.uniqueOrderID
								? projectDetail?.data?.order_info?.uniqueOrderID
								: shortID}
						</Typography>
						<Paper sx={{ width: "100%", overflow: "hidden" }}>
							<TableContainer>
								<Table stickyHeader aria-label="sticky table" sx={{}}>
									<TableHead>
										<StyledTableRow>
											<StyledTableCell sx={{ fontWeight: "600" }}>Transaction Type</StyledTableCell>
											<StyledTableCell sx={{ fontWeight: "600" }}>Date</StyledTableCell>
											<StyledTableCell sx={{ fontWeight: "600" }}>Amount</StyledTableCell>
											<StyledTableCell sx={{ fontWeight: "600" }}>Status</StyledTableCell>
										</StyledTableRow>
									</TableHead>
									<TableBody>
										{filteredTransactions?.map((transaction, index) => (
											<StyledTableRow key={index}>
												<StyledPopupTableCell>{transaction.transactionType}</StyledPopupTableCell>
												<StyledPopupTableCell>{moment(transaction.actualPaymentDate).format("MM/DD/YY")}</StyledPopupTableCell>
												<StyledPopupTableCell>{"$" + transaction.amount.toFixed(2)}</StyledPopupTableCell>
												<StyledPopupTableCell>{transaction.paymentStatus}</StyledPopupTableCell>
											</StyledTableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Paper>

						<Formik
							initialValues={initialValues}
							onSubmit={(values, formikHelpers) => {
								values.orderId = projectDetail.data?.order_info?._id;
								values.transactionId = filteredTransactions[0]._id;
								dispatch(refundTransaction(values));
								console.log("values:---------------------", values);
							}}
							validationSchema={object({
								refundAmount: Yup.number()
									.required("*Please enter an amount to refund")
									.max(
										filterAmount[transactionType] || projectDetail.data?.order_info?.totalAmount.toFixed(2),
										`Maximum refund value must be less or equal to ${filterAmount[transactionType] || projectDetail.data?.order_info?.totalAmount.toFixed(
											2
										)}.`
									)
									.test(
										"not-only-zeroes",
										"Number cannot only contain zeroes",
										function (value) {
											if (value === null || value === undefined) {
												return true; // Don't validate null or undefined values
											}
											const stringValue = value.toString(); // Convert the number to a string
											return !/^0+$/.test(stringValue); // Return true if the string does not only contain zeroes
										}
									),
							})}
						>
							{({ setFieldValue, errors, touched, values }) => (
								<Form>
									<Field
										as={StyledTextField}
										sx={{ width: "100%" }}
										id="refundAmount"
										name="refundAmount"
										label="Amount"
										variant="outlined"
										type="number"
										inputProps={{
											step: "0.01",
											min: "0",
										}}
										onChange={(e) => {
											const value = e.target.value;
											if (!value.startsWith("-")) {
												setFieldValue("refundAmount", value);
											}
										}}
										error={
											Boolean(errors.refundAmount) &&
											Boolean(touched.refundAmount)
										}
										helperText={
											errors.refundAmount
												? Boolean(touched.refundAmount) && errors.refundAmount
												: "Max available for refund"
										}
									/>
									<DialogActions>
										<Button variant="outlined" onClick={handleRefundModal}>
											Cancel
										</Button>
										<Button
											type="submit"
											variant="contained"
											disabled={
												!touched.refundAmount ||
												Boolean(errors.refundAmount) ||
												!values.refundAmount
											}
										>
											Refund
										</Button>
									</DialogActions>
								</Form>
							)}
						</Formik>
					</RefundContainer>
				</Dialog>
			) : null}
		</>
	);
};

export default ProjectCustomersTable;
