import {
  Box,
  Table,
  TableContainer,
  Typography,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  FormGroup,
  FormControlLabel,
  Checkbox,
  DialogActions,
} from "@mui/material";
import React, { useState , useMemo } from "react";
import BeardcrumNavigator from "../../components/BeardcrumNavigator";
import Sidebar from "../../components/Sidebar";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import DynamicFilter from "./DynamicFilter";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";

import { useDispatch, useSelector } from "react-redux";
import TableLink from "../../components/TableLink";
import AddCompany from "./AddCompany";

import { handleAddCompanyModal } from "../../features/login/loginSlice";
import { useEffect } from "react";
import {
  addCompany,
  getCompanies,
} from "../../features/companies/companySlice";
import { CSVLink } from "react-csv";
import Loading from "../../components/Loading";
import Alert from "@mui/material/Alert";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { companyResponseClr } from "../../features/companies/companySlice";
import CloseIcon from "@mui/icons-material/Close";
import FilterListIcon from "@mui/icons-material/FilterList";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import NorthIcon from "@mui/icons-material/North";
import { Link, useLocation } from "react-router-dom";

const columns = [
  { id: "companyName", label: "Company", minWidth: 100, fontWeight: "600" },
  {
    id: "representativeName",
    label: "Representative",
    minWidth: 100,
    fontWeight: "600",
  },
  {
    id: "representativeNumber",
    label: "Representative Number",
    minWidth: 100,
    fontWeight: "600",
  },
  {
    id: "representativeEmail",
    label: "Representative Email",
    minWidth: 170,
    fontWeight: "600",
  },
  {
    id: "numberOfUsers",
    label: "Number of Users",
    minWidth: 150,
    fontWeight: "600",
  },
  {
    id: "numberOfOpenProjects",
    label: "Projects Open",
    minWidth: 100,
    fontWeight: "600",
  },
  {
    id: "numberOfCompletedProjects",
    label: "Projects Completed",
    minWidth: 100,
    fontWeight: "600",
  },
  {
    id: "dateLastActive",
    label: "Date Last Active",
    minWidth: 150,
    fontWeight: "600",
  },
  {
    id: "companyStatus",
    label: "Status",
    fontWeight: "600",
    textTransform: "capitalize",
    minWidth: 100,
  },
  // { id: "actions", label: "Actions", fontWeight: "600", minWidth: 150 },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.gray,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
    backgroundColor: "#ddd",
  },
}));

const Companies = () => {
  const { isDrawerOpen, isAddCompanyModal } = useSelector(
    (store) => store.login
  );
  const { list, isLoading, alert, isUpdated, responseStatus, responseMsg } =
    useSelector((store) => store.company);
    
  const dispatch = useDispatch();
  const location = useLocation();

  const [alertDialog, setAlertDialog] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");
  const [filterModalOpen, setFilterModalOpen] = React.useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  
  const [filters, setFilters] = useState({
    companyName: [],
    representativeName: [],
    representativeNumber: [],
    representativeEmail: [],
    numberOfUsers: [],
    numberOfOpenProjects: [],
    numberOfCompletedProjects: [],
    dateLastActive: [],
    companyStatus: [],
  });

  
  const applyFilters = (data) => {
    // Log the input data for debugging
   
  
    // Ensure data is an array before proceeding
    if (!Array.isArray(data)) return []; // Return an empty array if data is not an array
  
    return data.filter((row) => {
    // debugger

      // Check if the row matches the search term in any column
      const searchMatch = Object.values(row).some((value) =>
        String(value).toLowerCase().includes(searchValue)
      );

       //setFilters(data.filter)
  
      // If search match is false, the row should be excluded
      if (!searchMatch) return false;
  
      // Proceed with other filters if searchMatch is true
      // return Object.entries(filters).every(([key, filterValue]) => {
      //   if (!filterValue) return true; // Skip empty filters
      //   const rowValue = row[key] ? String(row[key]) : '';
      //   const normalizedFilterValue = String(filterValue);
      //   return rowValue.includes(normalizedFilterValue);


      // });
      return Object.entries(selectedFilters).every(([key, filterValue]) => {
        if (!filterValue.size) return true; 
        const rowValue = row[key] ? String(row[key]) : '';
        return filterValue.has(rowValue);
      });
    });
  };

  
//   const applyFilters = (selectedFilters) => {
//     console.log('Current Filters Before Update:', filters);
    
//     const updatedFilters = {
//         ...filters,
//         ...selectedFilters,
//     };
    
//     console.log('Updated Filters:', updatedFilters);
//     setFilters(updatedFilters);
// };


  // const applyFilters = (selectedFilters) => {
  //   console.log('Current Filters Before Update:', filters);
  //   console.log('Applying filters: in apply filter fucntion', selectedFilters);
    
  //   const updatedFilters = {
  //     ...filters,
  //     ...selectedFilters,
  //   };
  
  //   console.log(updatedFilters , "updated filtersssssssssssssssss")
  //   console.log(filters , "before setting in filters state")
    
  //   setFilters(updatedFilters);
  //   console.log(filters , "after setting in filters state")
  
  // };
  
  // // Log updated filters whenever 'filters' state changes
  // useEffect(() => {
  //   console.log('Current Filters After Update:', filters);
  // }, [filters]); // This will run every time 'filters' state changes
  
  
  // const filteredData = useMemo(() => {
  //   if (!list?.data) return [];
  
  //   const searchLower = searchValue.toLowerCase();
  //   return list.data.filter(row => 
  //     columns.some(column => 
  //       row[column.id]?.toString().toLowerCase().includes(searchLower)
  //     )
  //   ).filter(row => {
  //     // Apply filters
  //     return Object.keys(filters).every(key => {
  //       if (filters[key].size > 0) {
  //         return filters[key].has(row[key]);
  //       }
  //       return true; // No filter for this key
  //     });
  //   });
  // }, [list?.data, columns, searchValue, filters]);
  
  
  const applyFiltersAndSorting = (data) => {
    let filteredData = applyFilters(data);
    
    if (sortConfig.key !== null) {
      filteredData = filteredData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return filteredData;
  };
  

  useEffect(() => {
    dispatch(getCompanies());
  }, [dispatch, isAddCompanyModal]);

  useEffect(() => {
    dispatch(getCompanies());
  } ,[addCompany]);

  const breadcrumbs = [
    <Typography
      key="3"
      color="text.primary"
      style={{
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "34px",
        lineHeight: "36px",
        color: "#000000",
      }}
    >
      Companies
    </Typography>,
  ];
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearch = (searchedValue) => {
    setSearchValue(searchedValue);
    setPage(0);
  };
  const [sortDirection, setSortDirection] = useState("desc"); // 'asc' for ascending, 'desc' for descending
  const [sortedCompanies, setSortedCompanies] = useState([]);

  const filteredCompanies = sortedCompanies.filter(
    (data) =>
      data.companyName?.toLowerCase().includes(searchValue) ||
      data.representativeName?.toLowerCase().includes(searchValue) ||
      data.representativeNumber?.toLowerCase().includes(searchValue) ||
      data.representativeEmail?.toLowerCase().includes(searchValue) ||
      data.numberOfUsers?.toString().includes(searchValue) || // Convert number to string
      data.numberOfOpenProjects?.toString().includes(searchValue) || // Convert number to string
      data.numberOfCompletedProjects?.toString().includes(searchValue) || // Convert number to string
      data.dateLastActive?.toLowerCase().includes(searchValue) ||
      data.companyStatus?.toLowerCase().includes(searchValue)
  );

  useEffect(() => {
    if (list?.data) {
      list.data.forEach(company => {
      });
    }
  }, [list?.data]);

  // useEffect(() => {
  //   dispatch(getCompanies());
  // }, [dispatch, location]);

  // filters={{
  //   companyName: [
  //     ...new Set(list?.data?.map(item => item.companyName).filter(Boolean)) ],

  //   representativeName: [
  //     ...new Set(list?.data?.map(item => item.representativeName).filter(Boolean)) ],
  //   representativeNumber: [
  //     ...new Set(list?.data?.map(item => item.representativeNumber).filter(Boolean)) ],

  //   representativeEmail: [
  //     ...new Set(list?.data?.map(item => item.representativeEmail).filter(Boolean)) ],

  //   numberOfUsers: [
  //     ...new Set(list?.data?.map(item => item.numberOfUsers).filter(Boolean)) ],

  //   numberOfOpenProjects: [
  //       ...new Set(list?.data?.map(item => item.numberOfOpenProjects).filter(Boolean)) ],
    
  //   numberOfCompletedProjects: [
  //       ...new Set(list?.data?.map(item => item.numberOfCompletedProjects).filter(Boolean)) ],

  //   dateLastActive: [
  //           ...new Set(list?.data?.map(item => item.dateLastActive).filter(Boolean)) ],
  //   companyStatus: [
  //             ...new Set(list?.data?.map(item => item.companyStatus).filter(Boolean)) ],

   
  // }}



  const filtersCompanies = {
    companyName: [
      ...new Set(list?.data?.map(item => item.companyName).filter(Boolean)),
    ],
    representativeName: [
      ...new Set(list?.data?.map(item => item.representativeName).filter(Boolean)),
    ],
    representativeNumber: [
      ...new Set(list?.data?.map(item => item.representativeNumber).filter(Boolean)),
    ],
    representativeEmail: [
      ...new Set(list?.data?.map(item => item.representativeEmail).filter(Boolean)),
    ],
    numberOfUsers: [
      ...new Set(list?.data?.map(item => item.numberOfUsers).filter(Boolean)),
    ],
    numberOfOpenProjects: [
      ...new Set(list?.data?.map(item => item.numberOfOpenProjects).filter(Boolean)),
    ],
    numberOfCompletedProjects: [
      ...new Set(list?.data?.map(item => item.numberOfCompletedProjects).filter(Boolean)),
    ],
    dateLastActive: [
      ...new Set(list?.data?.map(item => item.dateLastActive).filter(Boolean)),
    ],
    companyStatus: [
      ...new Set(list?.data?.map(item => item.companyStatus).filter(Boolean)),
    ],
  };


  const filterHeader = ["companyName", "representativeName", "representativeNumber", "representativeEmail", "numberOfUsers", "numberOfOpenProjects", "numberOfCompletedProjects", "dateLastActive", "companyStatus"];
  

  const [selectedFilters, setSelectedFilters] = useState(() => {
    const initialFilters = {};
    filterHeader.forEach(category => {
      initialFilters[category.toLowerCase()] = new Set();
    });
    return initialFilters;
  });

  const convertString = (input)=> {
    // Split the input string based on uppercase letters
    const splitWords = input.replace(/([A-Z])/g, ' $1').trim();
  
    // Capitalize the first letter of each word
    const capitalizedWords = splitWords
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  
    return capitalizedWords;
  }

  const handleToggle = (category, value) => {
    setSelectedFilters(prevFilters => {
      const updatedFilters = { ...prevFilters };
      updatedFilters[category] = new Set(updatedFilters[category]);
      if (updatedFilters[category].has(value)) {
        updatedFilters[category].delete(value);
      } else {
        updatedFilters[category].add(value);
      }
      return updatedFilters;
    });
  };

  const handleSubmit = (selectedFilters) => {
    setFilterModalOpen(false); 
    const filteredData = applyFilters(selectedFilters);
    // setFilters(filters);
    // applyFilters(selectedFilters); 
  };

  // Convert to an array of filter options
  const filtersArray = Object.entries(filters).map(([key, values]) => ({
    key,
    values,
  }));
  
  


  function renderTableCell(column, value, row) {
    switch (column.id) {
      case "companyName":
        return <TableLink text={value} route={row.id} />;
      case "representativeNumber":
        return (
          <Typography>
            <TableLink route={row.id} text={value} />
          </Typography>
        );
      case "representativeEmail":
        return (
          <Typography>
            <TableLink route={row.id} text={value} />
          </Typography>
        );
      case "dateLastActive":
        if (isNaN(Date.parse(value))) {
          return "";
        }
        const formattedDate = new Intl.DateTimeFormat("en-US", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
          timeZoneName: "short",
        }).format(new Date(value));
        return formattedDate;
      case "companyStatus":
        return value === "enable"
          ? "Enabled"
          : value === "disable"
          ? "Disabled"
          : value;

      case "numberOfCompletedProjects":
          return value || 0; 
      default:
        return column.format && typeof value === "number"
          ? column.format(value)
          : value;
    }
  }
  const SortButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  }));

  const sortLastLogin = () => {
    const sortedData = [...list?.data].sort((a, b) => {
      const dateA = new Date(a?.dateLastActive);
      const dateB = new Date(b?.dateLastActive);
      return sortDirection === "asc" ? dateB - dateA : dateA - dateB;
    });

    setSortDirection(sortDirection === "asc" ? "desc" : "asc"); // Toggle sort direction
    setSortedCompanies(sortedData);
  };

  useEffect(() => {
   
    if (list?.data) {
      setSortedCompanies(list.data);
    }
  }, [list?.data, dispatch]);

  if (isLoading || !sortedCompanies) {
    return <Loading />;
  }

  const getCsvData = () => {
    if (!list?.data) return [];
    
    // Map over the list.data and extract only the relevant fields
    return list.data.map(company => {
      let csvRow = {};
      columns.forEach(column => {
        csvRow[column.id] = company[column.id] || ""; // Ensure there's no undefined data
      });
      return csvRow;
    });
  };


 

  return (
    <div className="page-section">
      <Sidebar />
      <Box
        className="page-content"
        sx={{
          width: isDrawerOpen ? `calc(100% - 240px)` : `calc(100% - 57px)`,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "1rem",
            mb: 3,
          }}
        >
          <BeardcrumNavigator
            breadcrumbs={breadcrumbs ? breadcrumbs : "Beardcrums"}
          />
          <Box
            component="div"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <CSVLink data={getCsvData()} filename="companies.csv">
              <Button
                variant="outlined"
                className="bc-btn-outline"
                color="primary"
              >
                Export csv
              </Button>
            </CSVLink>
            <Button
              variant="text"
              sx={{
                backgroundColor:"#019EB2",
                color:"white",
                gap: "1rem",
                fontSize:15,
                padding:"8px",
                '&:hover': {
               color: "#019EB2", // Change this to the desired blue color
               border: "1px solid #0000001F"
              },
              }}
              onClick={() => dispatch(handleAddCompanyModal())}
            >
              Add Company
            </Button>
          </Box>
        </Box>

        {alert ? (
          <Alert
            severity={responseStatus}
            color={responseStatus}
            sx={{ mb: 3, width: "100%" }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  dispatch(companyResponseClr(false));
                  setAlertDialog(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {responseMsg}
          </Alert>
        ) : null}

        <Box
          component="div"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Box component="div">
            <Box
              sx={{
                background: "#FFFFFF",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "33px",
                height: 50,
                display: "flex",
                alignItems: "center",
                maxWidth: "208px",
                maxHeight: '30px',
                border: "1px solid #ddd",
                overflow: "hidden",
              }}
            >
              <SearchRoundedIcon
                sx={{
                  width: "16%",
                  marginLeft: "6px",
                }}
              />
              <input
                type="text"
                value={searchValue}
                // placeholder="Search"
                className="search-input"
                onChange={(e) => handleSearch(e.target.value.toLowerCase())}
              />
            </Box>
          </Box>
          <Box
              component='div'
              sx={{
              //  display: 'flex',
                alignItems: 'center',
                gap: '1rem',
                float: 'right',
              }}
            >
              <Button
                variant='outlined'
                className='bc-btn-outline'
                color='primary'
                onClick={() => setFilterModalOpen(true)}
              >
                Filter
              </Button>
            </Box> 
        </Box>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table" sx={{}}>
              {/* <TableHead>
                <StyledTableRow>
                  {columns.map((column) => (
                    <StyledTableCell key={column.id}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => requestSort(column.id)}
                      >
                        {column.label}
                        {column.id !== "actions" && (
                          <Box
                            component="span"
                            sx={{
                              display: "inline-flex",
                              alignItems: "center",
                              marginLeft: "5px",
                            }}
                          >
                            {sortConfig.key === column.id ? (
                              sortConfig.direction === "ascending" ? (
                                <NorthIcon fontSize="small" />
                              ) : (
                                <ArrowDownwardIcon fontSize="small" />
                              )
                            ) : (
                              <ArrowDownwardIcon fontSize="small" />
                            )}
                          </Box>
                        )}
                      </Box>
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              </TableHead> */}

<TableHead>
  <StyledTableRow>
    {columns.map((column) => (
      <StyledTableCell key={column.id}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: column.id === "companyName" ? "pointer" : "default", // Only pointer for companyName
          }}
          onClick={() => column.id === "companyName" && requestSort(column.id)} // Trigger sort only for companyName
        >
          {column.label}
          {column.id === "companyName" && (
            <Box
              component="span"
              sx={{
                display: "inline-flex",
                alignItems: "center",
                marginLeft: "5px",
              }}
            >
              {sortConfig.key === column.id ? (
                sortConfig.direction === "ascending" ? (
                  <NorthIcon fontSize="small" />
                ) : (
                  <ArrowDownwardIcon fontSize="small" />
                )
              ) : (
                <ArrowDownwardIcon fontSize="small" />
              )}
            </Box>
          )}
        </Box>
      </StyledTableCell>
    ))}
  </StyledTableRow>
</TableHead>

<TableBody>
  {filteredCompanies.length === 0 ? (
    <StyledTableRow>
      <StyledTableCell colSpan={columns.length} align="center">
        No results found
      </StyledTableCell>
    </StyledTableRow>
  ) : (
    applyFiltersAndSorting(sortedCompanies)
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((row) => {
        return (
          <StyledTableRow hover role="checkbox" tabIndex={-1} key={row.id}>
            {columns.map((column) => {
              const value = row[column.id];
              return (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  sx={{ textTransform: column.textTransform }}
                >
                  {renderTableCell(column, value, row)}
                </StyledTableCell>
              );
            })}
          </StyledTableRow>
        );
      })
  )}
</TableBody>

            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={filteredCompanies.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      {isAddCompanyModal ? <AddCompany /> : null}

      {filterModalOpen && (
        <Dialog open={filterModalOpen} onClose={() => setFilterModalOpen(false)}>
        <DialogTitle>Filter Services</DialogTitle>
        <DialogContent>
          {filterHeader.map((category) => (
            <FormGroup key={category}>
              <Typography variant="subtitle1" fontWeight="bold">
                {convertString(category)}
                {/* {category} */}
              </Typography>
              {filtersCompanies[category]?.map((value) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedFilters[category]?.has(value) || false}
                      onChange={() => handleToggle(category, value)}
                    />
                  }
                  label={value}
                  key={value}
                  // onApply={applyFilters}
                />
              ))}
            </FormGroup>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFilterModalOpen(false)}>Cancel</Button>
          <Button onClick={(e) => handleSubmit(selectedFilters)}>Apply</Button>
        </DialogActions>
      </Dialog>  
)}

      <DynamicFilter
      //  open={filterModalOpen}
      //  onClose={() => {
      //   setFilterModalOpen(false);
      //   //resetFilters();
      // }}
     
      // filterHeader={["companyName", "representativeName", "representativeNumber" , "representativeEmail" , "numberOfUsers" , " numberOfOpenProjects" , "numberOfCompletedProjects" , "dateLastActive","companyStatus"]}
      // onApply={applyFilters}
    
/>
    </div>
  );
};

export default Companies;
