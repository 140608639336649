import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const initialState = {
  list: {},
  companyDetail: {},
  isLoading: false,
  alert: false,
  responseStatus: "",
  responseMsg: "",
  isUpdated: false,
};

export const getCompanies = createAsyncThunk(
  "company/getCompanies",
  async (thunkAPI) => {
    try {
      const resp = await axios(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/admin/company/listing`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log(resp , "responseeeeee")
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("");
    }
  }
)

export const addCompany = createAsyncThunk(
  "company/addCompany",
  async (values, thunkAPI) => {
    let formData = new FormData();

    // Append all values initially
    formData.append("addressOne", values.addressOne);
    formData.append("addressTwo", values.addressTwo);
    formData.append("city", values.city);
    formData.append("state", values.state);
    formData.append("zip", values.zip);
    formData.append("companyName", values.companyName);
    formData.append("companyStatus", values.companyStatus);
    formData.append("distanceWillingTravel", values.distanceWillingTravel);
    formData.append("representativeEmail", values.representativeEmail);
    formData.append("representativeName", values.representativeName);
    formData.append("representativeNumber", values.representativeNumber);
    values.services.forEach((item, index) => {
      formData.append(`services[${index}]`, item);
    });

    let uploadedImageUrl = null;

    try {
     
      if (values.image instanceof File) {

       
        const imageFormData = new FormData();
        imageFormData.append("image", values.image);

        
        const imageUploadResp = await axios.post(
          `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/admin/coupons/uploadimage`,
          imageFormData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        uploadedImageUrl = imageUploadResp.data.data.Location; // Get the URL of the uploaded image
      } else if (values.image) {
        uploadedImageUrl = values.image;
      }
      if (uploadedImageUrl) {
        formData.append("image", uploadedImageUrl);
      }
      
      const resp = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/admin/company/add`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return resp.data;
    } catch (error) {
      console.log("Error during add:", error.response || error.message);
      return thunkAPI.rejectWithValue(error.response || error.message); // Using rejectWithValue to handle errors appropriately
    }
  }
);

export const updateCompany = createAsyncThunk(
  "company/updateCompany",
  async (values, thunkAPI) => {
    console.log("update values:", values);

    let formData = new FormData();
    // Append all values except 'image' initially
    formData.append("addressOne", values.addressOne);
    formData.append("addressTwo", values.addressTwo);
    formData.append("city", values.city);
    formData.append("state", values.state);
    formData.append("zip", values.zip);
    formData.append("companyName", values.companyName);
    formData.append("companyStatus", values.companyStatus);
    formData.append("distanceWillingTravel", values.distanceWillingTravel);
    formData.append("representativeEmail", values.representativeEmail);
    formData.append("representativeName", values.representativeName);
    formData.append("representativeNumber", values.representativeNumber);
    values.services.forEach((item, index) => {
      formData.append(`services[${index}]`, item);
    });

    let uploadedImageUrl = values.image;

    try {
      // Check if 'values.image' is a File object and needs uploading
      if (values.image instanceof File) {
        console.log("Uploading image...");
       
        const imageFormData = new FormData();
        imageFormData.append("image", values.image);

        // Upload the image to S3
        const imageUploadResp = await axios.post(
          `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/admin/coupons/uploadimage`,
          imageFormData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        uploadedImageUrl = imageUploadResp.data.data.Location; // Get the URL of the uploaded image
        console.log("Image uploaded to:", uploadedImageUrl);
      }

      // Re-create FormData for company update, because we need to append the image URL last
      formData = new FormData();
      formData.append("addressOne", values.addressOne);
      formData.append("addressTwo", values.addressTwo);
      formData.append("city", values.city);
      formData.append("state", values.state);
      formData.append("zip", values.zip);
      formData.append("companyName", values.companyName);
      formData.append("companyStatus", values.companyStatus);
      formData.append("distanceWillingTravel", values.distanceWillingTravel);
      formData.append("representativeEmail", values.representativeEmail);
      formData.append("representativeName", values.representativeName);
      formData.append("representativeNumber", values.representativeNumber);
      values.services.forEach((item, index) => {
        formData.append(`services[${index}]`, item);
      });

      // Append the image URL (either the uploaded one or the existing URL)
      if (uploadedImageUrl) {
        formData.append("image", uploadedImageUrl);
      }

      console.log("FormData prepared for update:", formData);
      const resp = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/admin/company/update/${values.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log("Company update response:", resp.data);
      return resp.data;
    } catch (error) {
      console.log("Error during update:", error.response || error.message);
      return thunkAPI.rejectWithValue(error.response || error.message); // Using rejectWithValue to handle errors appropriately
    }
  }
);

export const singleCompanyDetail = createAsyncThunk(
  "company/singleCompanyDetail",
  async (id, thunkAPI) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      
      
      const resp = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/admin/company/companyDetail/${id}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log(resp.data , "response from all single compmay details")
      return resp.data;
    } catch (error) {
      console.log(error.response);
      return error.response;
    }
  }
);

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    companyResponseClr: (state, action) => {
      state.responseMsg = "";
      state.responseStatus = "";
      state.alert = false;
    },
    resetCompanyDetail: (state) => {
      state.companyDetail = {};
    },
  },
  extraReducers: {
    [getCompanies.pending]: (state) => {
      state.isLoading = true;
    },
    [getCompanies.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.list = action.payload;
    },
    [getCompanies.rejected]: (state) => {
      state.isLoading = false;
    },
    [addCompany.pending]: (state) => {
      state.isLoading = true;
    },
    [addCompany.fulfilled]: (state, action) => {
      if (action.payload.message) {
        state.responseStatus = "success";
        state.list.push(action.payload.company);
      } else {
        state.responseStatus = "error";
      }
      state.responseMsg = action.payload.message
        ? action.payload.message
        : action.payload.data.message;
      state.alert = true;
      state.isLoading = false;
    },
    [addCompany.rejected]: (state) => {
      state.isLoading = false;
    },
    [singleCompanyDetail.pending]: (state) => {
      state.isLoading = false;
    },
    [singleCompanyDetail.fulfilled]: (state, action) => {
      state.companyDetail = {};
      state.isLoading = false;
      state.companyDetail = action.payload;
    },
    [singleCompanyDetail.rejected]: (state) => {
      state.isLoading = false;
    },
    [updateCompany.pending]: (state) => {
      state.isLoading = true;
      state.isUpdated = false;
    },
    [updateCompany.fulfilled]: (state, action) => {
      if (action.payload.message) {
        state.responseStatus = "success";
        state.isUpdated = true; 
      } else {
        state.responseStatus = "error";
      }
      state.responseMsg = action.payload.message
        ? action.payload.message
        : action.payload.data.message;
      state.alert = true;
      state.isLoading = false;
    },
    [updateCompany.rejected]: (state) => {
      state.isLoading = false;
      state.isUpdated = false; // Ensure isUpdated is false when the update fails
    },
  },
});

export const { companyResponseClr, resetCompanyDetail } = companySlice.actions;

export default companySlice.reducer;
