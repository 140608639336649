import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { Container, TextField, Box, IconButton, Button } from "@mui/material";
import { handleEditCustomerModal } from "../../features/login/loginSlice";
import ModeEditOutlineRoundedIcon from "@mui/icons-material/ModeEditOutlineRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useRef } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  getCutomerDetail,
  updateCustomer,
} from "../../features/customer/customerSlice";
const EditCustomer = () => {
  const { isEditCustomerModal } = useSelector((store) => store.login);
  const { customerDetail } = useSelector((store) => store.customer);
  const dispatch = useDispatch();
  const { customerid } = useParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState(customerDetail.data?.statusBit);
  const [isImageChanged, setIsImageChanged] = useState(false);
  const [isImageRemoved, setIsImageRemoved] = useState(false);

  let imgInput = useRef(null);
  let imgRef = useRef(null);
  const pickFile = () => {
    imgInput.current.click();
  };
  const handleAddImg = (e) => {
    let file = e.target.files[0];
    let url = URL.createObjectURL(file);
    imgRef.current.src = url;
    setIsImageChanged(true);
    setIsImageRemoved(false); // Reset image removal state
  };

  const clearImgRef = () => {
    imgRef.current.src = "/images/circle-gray.png";
    setIsImageChanged(false); // Reset image changed state
    setIsImageRemoved(true); // Indicate that the image was removed
  };

  const handleStatus = () => {
    const newStatus = !status;
    setStatus(newStatus);
    formik.setFieldValue("statusBit", newStatus); // This updates Formik's state
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required("Please enter a name"),
    phone: Yup.string()
      .nullable() // This allows the value to be null
      .matches(
        /^5\d{9}$/,
        "Phone number must start with 5 and be 10 digits long"
      )
      .required("Please enter a phone number"),
  });

  console.log(customerDetail.data, "dataaatesttttttt");

  const formik = useFormik({
    initialValues: {
      email: customerDetail.data?.email || "",
      firstName: customerDetail.data?.firstName || "",
      lastName: customerDetail.data?.lastName || "",
      statusBit: customerDetail.data?.statusBit,
      phone: customerDetail.data?.phoneNumber,
      getImg: customerDetail.data?.profileImage || "", 
    },
  
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log(values , "values in submit fubction");
          values.statusBit = status;
  
      // If image has changed and there's a new file, set the file.
      if (isImageChanged && imgInput.current.files[0]) {
        values.profileImage = imgInput.current.files[0]; // File object
      } 
      else if (isImageRemoved) {
        values.profileImage = "/images/circle-gray.png"; 
      } 
      else if (!isImageChanged && !isImageRemoved) {
        values.profileImage = customerDetail.data?.profileImage || ""; 
      }
  
      console.log('Submitted values:', values);
      // Call your update customer dispatch with the updated values
      dispatch(updateCustomer(values))
        .then(() => {
          dispatch(getCutomerDetail(customerid)).then(() => {
            dispatch(handleEditCustomerModal());
            navigate(`/customers/${customerid}`);
          });
        })
        .catch((error) => {
          console.error("Failed to update customer: ", error);
        });
    },
  
    validationSchema,
  });
    
  return (
    <>
      <Dialog
        open={isEditCustomerModal}
        scroll="body"
        onClose={() => dispatch(handleEditCustomerModal())}
        aria-describedby="scroll-dialog-description"
      >
        {console.log(
          "adsada :dasdsadsadasda ",
          customerDetail.data.profileImage
        )}
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle
            id="scroll-dialog-title"
            sx={{
              p: 1,
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "24px",
              lineHeight: "24px",
              letterSpacing: "0.18px",
              color: "#000000",
            }}
          >
            Edit Customer
          </DialogTitle>
          <Box
            component="div"
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              mr: 3,
            }}
          >
            <Box component="div" className="addImg">
              <img
                src={
                  customerDetail.data?.profileImage
                    ? customerDetail.data?.profileImage
                    : "/images/circle-gray.png"
                }
                // alt="Profile"
                ref={imgRef}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />

              <input
                type="file"
                hidden
                id="getImg"
                name="getImg"
                ref={imgInput}
                onChange={(e) => handleAddImg(e)}
              />
            </Box>
            <Box component="div">
              <IconButton onClick={pickFile}>
                <ModeEditOutlineRoundedIcon />
              </IconButton>
              <IconButton onClick={clearImgRef}>
                <CloseRoundedIcon />
              </IconButton>
            </Box>
          </Box>
          <DialogContent
            sx={{
              p: 0,
            }}
          >
            <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
              <Container maxWidth="xs">
                <div className="group">
                  <p className="btn_label">Status</p>
                  <div className="btn_group">
                    <button
                      type="button"
                      className={status ? "active" : ""}
                      onClick={handleStatus}
                    >
                      Active
                    </button>
                    <button
                      type="button"
                      className={status ? "" : "active"}
                      onClick={handleStatus}
                    >
                      Inactive
                    </button>
                  </div>
                </div>
                {/* <TextField
                  sx={{ width: "100%", mb: 4, mt: 5 }}
                  id="firstName"
                  name="firstName"
                  label="Name"
                  variant="outlined"
                  {...formik.getFieldProps("firstName")}
                  error={
                    Boolean(formik.errors.firstName) &&
                    Boolean(formik.touched.firstName)
                  }
                  helperText={
                    Boolean(formik.touched.firstName) && formik.errors.firstName
                  }
                /> */}

                <TextField
                sx={{ width: "100%", mb: -2, mt: 5 }}
                id="firstName"
                name="firstName"
                label="First Name"
                variant="outlined"
                {...formik.getFieldProps("firstName")}
                error={
                  Boolean(formik.errors.firstName) &&
                  Boolean(formik.touched.firstName)
                }
                helperText={
                  Boolean(formik.touched.firstName) && formik.errors.firstName
                }
                />
               <TextField
                sx={{ width: "100%", mb: 3, mt: 5 }}
                id="lastName"
                name="lastName"
                label="Last Name"
                variant="outlined"
                {...formik.getFieldProps("lastName")}
                error={
                  Boolean(formik.errors.lastName) &&
                  Boolean(formik.touched.lastName)
                }
                helperText={
                  Boolean(formik.touched.lastName) && formik.errors.lastName
                }
                />
                <TextField
                  sx={{ width: "100%", mb: 3 }}
                  id="email"
                  name="email"
                  label="Email"
                  variant="outlined"
                  disabled
                  {...formik.getFieldProps("email")}
                  error={
                    Boolean(formik.errors.email) &&
                    Boolean(formik.touched.email)
                  }
                  helperText={
                    Boolean(formik.touched.email) && formik.errors.email
                  }
                />
                <TextField
                  sx={{ width: "100%", mb: 3 }}
                  id="phone"
                  name="phone"
                  label="Phone"
                  variant="outlined"
                  {...formik.getFieldProps("phone")}
                  error={
                    Boolean(formik.errors.phone) &&
                    Boolean(formik.touched.phone)
                  }
                  helperText={
                    Boolean(formik.touched.phone) && formik.errors.phone
                  }
                />
              </Container>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => dispatch(handleEditCustomerModal())}
            >
              Cancel
            </Button>
            {/* <Button variant='contained' onClick={() => dispatch(handleEditCustomerModal())}>Save</Button> */}
            <Button
              type="submit"
              variant="contained"
              disabled={
                !formik.isValid ||
                (!formik.dirty &&
                  formik.values.statusBit === customerDetail.data?.statusBit &&
                  !isImageChanged &&
                  !isImageRemoved)
              }
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default EditCustomer;
