import { Box } from "@mui/material";
import NorthIcon from '@mui/icons-material/North';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { StyledTableCell, StyledTableRow } from './StyledTableComponents';

const ServiceTableHeader = ({ columns, requestSort, sortConfig }) => {
  const shouldHideSorterIcon = (columnId) => {
    const lowerCaseId = columnId.toLowerCase();
    return lowerCaseId.includes('action');
  };

  return (
    <StyledTableRow>
      {columns?.map(column => (
        <StyledTableCell key={column.id} style={{ minWidth: column.minWidth }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: !shouldHideSorterIcon(column.id) ? 'pointer' : 'default',
              '&:hover .sortIcon': {
                visibility: !shouldHideSorterIcon(column.id) ? 'visible' : 'hidden',
              },
            }}
            onClick={() => !shouldHideSorterIcon(column.id) && requestSort(column.id)} 
          >
            <b>{column.label}</b>
            <Box
              component="span"
              className="sortIcon"
              sx={{
                display: "inline-flex",
                alignItems: "center",
                marginLeft: "5px",
                visibility: sortConfig.key === column.id ? 'visible' : 'hidden',
              }}
            >
              {!shouldHideSorterIcon(column.id) && ( 
                sortConfig.key === column.id ? (
                  sortConfig.direction === 'ascending' ? (
                    <NorthIcon fontSize="small" />
                  ) : (
                    <ArrowDownwardIcon fontSize="small" />
                  )
                ) : (
                  <ArrowDownwardIcon fontSize="small" />
                )
              )}
            </Box>
          </Box>
        </StyledTableCell>
      ))}
    </StyledTableRow>
  );
};

export default ServiceTableHeader;
