import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  projects: {},
  projectDetail: {},
  staffReassignedList: {},
  listofAssignStaff: {},
  listofCompanies: {},
  isLoading: false,
  alert: false,
  responseStatus: "",
  responseMsg: "",
  assignCompanyResponse : ""
};

export const getProjects = createAsyncThunk(
  "project/getProjects",
  async (thunkAPI) => {
    try {
      
      const resp = await axios(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/admin/projects/listing`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);
export const getAssignedProject = createAsyncThunk(
  "project/getAssignedProject",
  async (id, thunkAPI) => {
    try {
      console.log(id , "iddddddddddd project")
      const resp = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/admin/staff/assignStaff/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log("pkt: :::", resp.data);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);

export const getStaffForAssign = createAsyncThunk(
  "project/getStaffForAssign",
  async (companyId, thunkAPI) => {
    try {
      const token = localStorage.getItem("token")
      const resp = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/admin/staff/listofAssignStaff`,
        {
          params: {
            companyId: companyId,  
          },
          headers: {
            Authorization: `Bearer ${token}`, 
          },
        }
      );
      return resp.data;
    } catch (error) {
      console.error("Error fetching staff for assignment:", error);
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);

export const getCompaniesForAssign = createAsyncThunk(
  "project/getCompaniesForAssign",
  async (id, thunkAPI) => {
    try {
      console.log("hit ting getcompanies");
      const resp = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/mobile/api/listofcompanies`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);
export const singleProjectDetail = createAsyncThunk(
  "project/singleProjectDetail",
  async (id, thunkAPI) => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/admin/projects/projectDetail/${id}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log(resp, "response of single project--------------");
      return resp.data;
    } catch (error) {
 
      return error.response;
    }
  }
);

export const rescheduleProject = createAsyncThunk(
  "project/rescheduleProject",
  async (values, thunkAPI) => {
    let id = thunkAPI.getState().project.projectDetail.data.order_info._id;
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/admin/projects/changeProjectStatus/${id}`,
        values,
        {
          headers: {
            // "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      // console.log(resp);
      return resp.data;
    } catch (error) {
      // console.log(error.response);
      // return "something went wrong";
      return error.response;
    }
  }
);
export const assignProjectToUser = createAsyncThunk(
  "project/assignProjectToUser",
  async (values, thunkAPI) => {
    try {
      console.log(values , "valuesssssss")
      console.log("user namee or what??? :: ", values);
      const resp = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/admin/projects/assignProjectToUser`,
        values,
        {
          headers: {
            // "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log("reasign  assign project to user", resp);
      return resp.data;
    } catch (error) {
      // console.log(error.response);
      // return "something went wrong";
      return error.response;
    }
  }
);
export const assignCompanyToOrder = createAsyncThunk(
  "project/assignCompanyToOrder",
  async (values, thunkAPI) => {
    try {
      console.log("values" , values);
      const resp = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/admin/projects/assignComanyToOrder`,

        values,
        {
          headers: {
            // "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      // console.log(resp);
      return resp.data;
    } catch (error) {
      // console.log(error.response);
      // return "something went wrong";
      return error.response;
    }
  }
);
export const changeProjectStatusAdmin = createAsyncThunk(
  "project/changeProjectStatusAdmin",
  async (values, thunkAPI) => {
    console.log("Initial values received:", values);

    // Standardize orderStatusDate to a consistent format
    if (values.orderStatusDate) {
      console.log("Original orderStatusDate:", values.orderStatusDate);

      // Extract the date parts (year, month, day) from the selected date
      const selectedDate = new Date(values.orderStatusDate);
      const year = selectedDate.getFullYear();
      const month = selectedDate.getMonth(); // Month is 0-based
      const day = selectedDate.getDate();

      // Create a new Date object at midnight UTC on the selected date
      const utcDate = new Date(Date.UTC(year, month, day));
      console.log("Date set to midnight UTC:", utcDate);

      // Convert to ISO string in UTC format (only date portion will matter)
      values.orderStatusDate = utcDate.toISOString().split("T")[0];
      console.log("Standardized orderStatusDate in YYYY-MM-DD format:", values.orderStatusDate);
    } else {
      console.log("No orderStatusDate provided in values.");
    }

    // Retrieve ID from the state
    let id = thunkAPI.getState().project.projectDetail.data.order_info._id;
    console.log("Project ID retrieved from state:", id);

    try {
      console.log('values here print: ', values)
      const resp = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/admin/projects/changeProjectStatus/${id}`,
        values,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      console.log("Response from backend:", resp.data);
      return resp.data;
    } catch (error) {
      console.error("Error response from backend:", error.response);
      return error.response;
    }
  }
);



export const reassignProjectStaff = createAsyncThunk(
  "project/reassignProjectStaff",
  async (thunkAPI) => {
    let id = thunkAPI.getState().project.projectDetail.data.order_info._id;
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/admin/staff/assignStaff/${id}`,
        {
          headers: {
            // "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log(resp);
      // return resp.data;
    } catch (error) {
      console.log(error.response);
      // return "something went wrong";
      // return error.response;
    }
  }
);

export const refundTransaction = createAsyncThunk(
  "project/refundTransaction",
  async (values, thunkAPI) => {
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/mobile/api/stripe-refund-payment`,
        values,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log(resp, "transaction-------- refund");
      window.location.reload();
      // console.log(resp);
      return resp.data;
    } catch (error) {
      console.log(error.response, "error in refund transaction------");
      // return "something went wrong";
      return error.response;
    }
  }
);
export const paymentLoggedStatus = createAsyncThunk(
  "project/paymentLogged",
  async (values, thunkAPI) => {
    try {
      values = {
        orderId: values,
      };
      const resp = await axios.put(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/admin/transactions/paymentLoggedStatus`,
        values,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      return resp.data;
    } catch (error) {
      console.log(error.response);
      // return "something went wrong";
      return error.response;
    }
  }
);
export const deleteAssignStaff = createAsyncThunk(
  "project/deleteAssignStaff",
  async (id, thunkAPI) => {
    try {
      const resp = await axios.delete(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/admin/staff/deleteAssignStaff/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      // console.log(resp);
      return resp.data;
    } catch (error) {
      console.log(error.response);
      // return "something went wrong";
      return error.response;
    }
  }
);

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    projectResponseClr: (state, action) => {
      state.responseMsg = "";
      state.responseStatus = "";
      state.alert = false;
    },
  },
  extraReducers: {
    [getProjects.pending]: (state) => {
      state.isLoading = true;
    },
    [getProjects.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.projects = action.payload;
    },
    [getProjects.rejected]: (state) => {
      state.isLoading = false;
    },
    [getAssignedProject.pending]: (state) => {
      state.isLoading = true;
    },
    [getAssignedProject.fulfilled]: (state, action) => {
      console.log(action);
      state.isLoading = false;
      state.staffReassignedList = action.payload;
    },
    [getAssignedProject.rejected]: (state) => {
      state.isLoading = false;
    },
    [assignProjectToUser.pending]: (state, action)=> {
      state.isLoading = true;
    },
    [assignCompanyToOrder.fulfilled]: (state , action)=> {
      state.isLoading = false;
      state.assignCompanyResponse = action.payload
    },
    [assignCompanyToOrder.rejected]: (state , action)=> {
      state.isLoading = false;
    },
    [getStaffForAssign.pending]: (state) => {
      state.isLoading = true;
    },
    [getStaffForAssign.fulfilled]: (state, action) => {
      console.log(action);
      state.isLoading = false;
      state.listofAssignStaff = action.payload;
    },
    [getStaffForAssign.rejected]: (state) => {
      state.isLoading = false;
    },
    [getCompaniesForAssign.pending]: (state) => {
      state.isLoading = false;
    },
    [getCompaniesForAssign.fulfilled]: (state, action) => {
      console.log(action);
      state.isLoading = false;
      state.listofCompanies = action.payload;
    },
    [getCompaniesForAssign.rejected]: (state) => {
      state.isLoading = false;
    },
    [singleProjectDetail.pending]: (state) => {
      state.isLoading = true;
    },
    [singleProjectDetail.fulfilled]: (state, action) => {
      console.log(action);
      state.isLoading = false;
      state.projectDetail = action.payload;
    },
    [singleProjectDetail.rejected]: (state) => {
      state.isLoading = false;
    },
    [refundTransaction.pending]: (state) => {
      state.isLoading = true;
    },
    [refundTransaction.fulfilled]: (state, action) => {
      if (action.payload.message) {
        state.responseStatus = "success";
      } else {
        state.responseStatus = "error";
      }
      state.responseMsg = action.payload.message
        ? action.payload.message
        : action.payload.data.message;
      state.alert = true;
      state.isLoading = false;
    },
    [refundTransaction.rejected]: (state) => {
      state.isLoading = false;
    },
    [rescheduleProject.pending]: (state) => {
      state.isLoading = true;
    },
    [rescheduleProject.fulfilled]: (state, action) => {
      if (action.payload.message) {
        state.responseStatus = "success";
      } else {
        state.responseStatus = "error";
      }
      state.responseMsg = action.payload.message
        ? action.payload.message
        : action.payload.data.message;
      state.alert = true;
      state.isLoading = false;
    },
    [rescheduleProject.rejected]: (state) => {
      state.isLoading = false;
    },
    [changeProjectStatusAdmin.pending]: (state) => {
      state.isLoading = true;
    },
    [changeProjectStatusAdmin.fulfilled]: (state, action) => {
      if (action.payload.message) {
        state.responseStatus = "success";
      } else {
        state.responseStatus = "error";
      }
      state.responseMsg = action.payload.message
        ? action.payload.message
        : action.payload.data.message;
      state.alert = true;
      state.isLoading = false;
      // state.responseStatus = action.payload.message;
    },
    [changeProjectStatusAdmin.rejected]: (state) => {
      state.isLoading = false;
    },
    [reassignProjectStaff.pending]: (state) => {
      state.isLoading = true;
    },
    [reassignProjectStaff.fulfilled]: (state, action) => {
      if (action.payload.message) {
        state.responseStatus = "success";
      } else {
        state.responseStatus = "error";
      }
      state.responseMsg = action.payload.message
        ? action.payload.message
        : action.payload.data.message;
      state.alert = true;
      state.staffReassignList = action.payload;
      state.isLoading = false;
    },
    [reassignProjectStaff.rejected]: (state) => {
      state.isLoading = false;
    },
    [assignProjectToUser.pending]: (state) => {
      state.isLoading = true;
    },
    [assignProjectToUser.fulfilled]: (state, action) => {
      console.log(action);
      if (action.payload.message) {
        state.responseStatus = "success";
      } else {
        state.responseStatus = "error";
      }
      state.responseMsg = action.payload.message
        ? action.payload.message
        : action.payload.data.message;
      state.alert = true;
      state.isLoading = false;
    },
    [assignProjectToUser.rejected]: (state) => {
      state.isLoading = false;
    },
    [deleteAssignStaff.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteAssignStaff.fulfilled]: (state, action) => {
      console.log(action);
      if (action.payload.message) {
        state.responseStatus = "success";
      } else {
        state.responseStatus = "error";
      }
      state.responseMsg = action.payload.message
        ? action.payload.message
        : action.payload.data.message;
      state.alert = true;
      state.isLoading = false;
    },
    [deleteAssignStaff.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { projectResponseClr } = projectSlice.actions;

export default projectSlice.reducer;
