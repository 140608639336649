import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Button, Container, FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import { handleEditAdminPortalUserModal } from '../../features/login/loginSlice';
import { useFormik } from "formik";
import * as Yup from 'yup';
import { getPortalUser, updateAdmin } from '../../features/admin-portal-user/adminPortalUserSlice';

const EditAdminPortalUser = () => {
  const dispatch = useDispatch();
  const { isEditAdminPortalUserModal, selectedAdmin } = useSelector((store) => store.login);

  const formik = useFormik({
    initialValues: selectedAdmin || {
      firstName: '',
      lastName: '',
      email: '',
      userType: 'admin',
      statusBit: false,
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
      .trim()
      .matches(/^[^\s]+(\s+[^\s]+)*$/, '*Please enter a valid first name')
      .required('*Please enter a first name'),
    lastName: Yup.string()
      .trim()
      .matches(/^[^\s]+(\s+[^\s]+)*$/, '*Please enter a valid last name')
      .required('*Please enter a last name'),
      email: Yup.string()
      .email('*Please enter an email address')
      .test('valid-domain', '*Please enter an email address', function (value) {
        const domain = value?.split('@')[1];
        if (!domain) return false;
        const domainParts = domain?.split('.');
        return domainParts.length > 1 && domainParts[domainParts.length - 1].length > 1;
      })
      .required('*Please enter an email address'),
    }),
    onSubmit: (values) => {
      dispatch(updateAdmin(values))
        .then(() => {
          // This code will execute after updateAdmin has successfully completed
          dispatch(handleEditAdminPortalUserModal());
          dispatch(getPortalUser()); // Refetch the admin users list

        })
        .catch((error) => {
          // Handle any errors from the updateAdmin action here
          console.error('Failed to update admin:', error);
        });
    },
  });

   console.log()
  useEffect(() => {
    if (selectedAdmin) {
      formik.setValues({
        id: selectedAdmin.id,
        firstName: selectedAdmin.firstName || '',
        lastName: selectedAdmin.lastName || '',
        email: selectedAdmin.email || '',
        userType: selectedAdmin.userType || 'admin',
        statusBit: selectedAdmin.statusBit !== undefined ? selectedAdmin.statusBit : false,
      });
    }
  }, [selectedAdmin, formik.setValues]);

  return (
    <Dialog
      open={isEditAdminPortalUserModal}
      onClose={() => dispatch(handleEditAdminPortalUserModal())}
    >
      <DialogTitle>Manage Admin User</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
        <DialogContentText component={'div'}>
            <Container maxWidth='xs'>
              <TextField 
                sx={{ width: '100%', mb: 4, mt: 5 }}
                label="First Name"
                name="firstName"
                {...formik.getFieldProps('firstName')}
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
              <TextField 
                sx={{ width: '100%', mb: 4 }}
                label="Last Name"
                name="lastName"
                {...formik.getFieldProps('lastName')}
                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
              <TextField 
                sx={{ width: '100%', mb: 4 }}
                label="Email"
                name='email'
                {...formik.getFieldProps('email')}
                error={formik.touched.email && Boolean(formik.errors.email)}  
                helperText={formik.touched.email && formik.errors.email}
              />
              <FormControl fullWidth sx={{ mb: 3 }}>
                <InputLabel>Role</InputLabel>
                <Select
                  label="Role"
                  name="userType"
                  {...formik.getFieldProps('userType')}
                >
                  <MenuItem value='admin'>Admin</MenuItem>
                  {/* Add other roles as needed */}
                </Select>
              </FormControl>
          <div className="group">
            <p className="btn_label">Status</p>
            <div className="btn_group">
              <button 
                type="button" 
                className={formik.values.statusBit ? 'active' : ''} 
                onClick={() => formik.setFieldValue('statusBit', true)}
              >
                Enabled
              </button>
              <button 
                type="button" 
                className={!formik.values.statusBit ? 'active' : ''} 
                onClick={() => formik.setFieldValue('statusBit', false)}

              >
                Disabled
                </button>
                </div>
                </div>
            </Container>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
  <Button 
    variant='outlined' 
    onClick={() => dispatch(handleEditAdminPortalUserModal())}
  >
    Cancel
  </Button>
  <Button 
    type="submit" 
    variant='contained' 
    disabled={!formik.dirty || !formik.isValid} // Disable if no changes or form is invalid
  >
    Save
  </Button>
</DialogActions>

      </form>
    </Dialog>
  );
};

export default EditAdminPortalUser;