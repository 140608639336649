import {
  Box,
  Table,
  TableContainer,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import BeardcrumNavigator from "../../components/BeardcrumNavigator";
import Sidebar from "../../components/Sidebar";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import TableLink from "../../components/TableLink";
import {
  getProjects,
  projectResponseClr,
} from "../../features/projects/projectSlice";
import Loading from "../../components/Loading";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { CSVLink } from "react-csv";
import moment from "moment/moment";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import NorthIcon from "@mui/icons-material/North";

import DynamicFilter from "./DynamicFilter";


const getNestedProperty = (obj, path) => {
  return path.split(".").reduce((acc, part) => acc && acc[part], obj);
};

const columns = [
  { id: "uniqueOrderID", label: "ProjectID", minWidth: 150, fontWeight: "600" },
  { id: "name", label: "Customer", minWidth: 150, fontWeight: "600" },
  {
    id: "serviceType",
    label: "Service Type",
    minWidth: 140,
    fontWeight: "600",
  },
  { id: "company", label: "Company", minWidth: 140, fontWeight: "600" },
  {
    id: "workersAssigned",
    label: "Workers Assigned",
    minWidth: 140,
    fontWeight: "600",
  },
  { id: "city", label: "City", minWidth: 100, fontWeight: "600" },
  { id: "state", label: "State", minWidth: 100, fontWeight: "600" },
  { id: "zipCode", label: "Zip", minWidth: 100, fontWeight: "600" },
  {
    id: "dateOrdered",
    label: "Date Ordered",
    minWidth: 200,
    fontWeight: "600",
  },
  {
    id: "dateScheduled",
    label: "Date Scheduled",
    minWidth: 200,
    fontWeight: "600",
  },
  {
    id: "dateCompleted",
    label: "Date Completed",
    minWidth: 200,
    fontWeight: "600",
  },
  {
    id: "totalPaidByCustomer",
    label: "Total Paid by Customer",
    minWidth: 200,
    fontWeight: "600",
  },
  {
    id: "refundToCustomer",
    label: "Refund To Customer",
    minWidth: 200,
    fontWeight: "600",
  },
  {
    id: "paidToContractor",
    label: "Paid To Contractor",
    minWidth: 200,
    fontWeight: "600",
  },
  {
    id: "status",
    label: "Status",
    minWidth: 200,
    fontWeight: "600",
    textTransform: "capitalize",
  },
  {
    id: "lastStatusUpdate",
    label: "Last Status Update",
    minWidth: 200,
    fontWeight: "600",
  },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.gray,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
    backgroundColor: "#ddd",
  },
}));
const Projects = () => {
  const dispatch = useDispatch();
  const { isDrawerOpen } = useSelector((store) => store.login);
  const { isLoading, projects } = useSelector((store) => store.project);
  console.log('projectsprojectsprojectsprojectsprojectsprojects: ',projects)
  const [filteredProjects, setFilteredProjects] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [sortedProjects, setsortedProjects] = React.useState([]);
  const [sortDirection, setSortDirection] = React.useState("desc"); // 'asc' for ascending, 'desc' for descending
  const [selectedFilter, setSelectedFilter] = React.useState("ALL");
  const sortProjects = (projectsToSort, sortConfig) => {
    return projectsToSort?.sort((a, b) => {
      if (sortConfig?.direction === "asc") {
        return a[sortConfig.key] < b[sortConfig.key] ? -1 : 1;
      } else {
        return a[sortConfig.key] > b[sortConfig.key] ? -1 : 1;
      }
    });
  };
  const tabFilters = ["ALL", "Pending Payment"];
  const [sortConfig, setSortConfig] = React.useState({
    key: "lastStatusUpdate",
    direction: "desc",
  });

  const sortLastLogin = () => {
    const nextSortDirection = sortDirection === "asc" ? "desc" : "asc";

    const sortedData = [
      ...(sortedProjects.length > 0 ? sortedProjects : projects?.data),
    ].sort((a, b) => {
      const dateA = new Date(a?.updatedAt);
      const dateB = new Date(b?.updatedAt);

      return nextSortDirection === "asc" ? dateA - dateB : dateB - dateA;
    });

    // Now update the sort direction and the sorted users list
    setSortDirection(nextSortDirection); // Update the sort direction here
    setsortedProjects(sortedData);
  };
  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    sortLastLogin(); // Call the sorting function whenever sort is requested
  };

  useEffect(() => {
    if (projects.data) {
      const filteredData = projects.data.filter(
        (data) =>
          data.name?.toLowerCase().includes(searchValue) ||
          data.uniqueOrderID?.toString().toLowerCase().includes(searchValue) ||
          data.company?.toLowerCase().includes(searchValue) ||
          data.company?.toLowerCase().includes(searchValue) ||
          data.orderStatus.toLowerCase().includes(searchValue) ||
          data.orderdetails[0]?.serviceName
            .toLowerCase()
            .includes(searchValue) ||
          data?.orderdetails[0]?.property?.city
            .toLowerCase()
            .includes(searchValue) ||
          data?.orderdetails[0]?.property?.state
            .toLowerCase()
            .includes(searchValue) ||
          data?.orderdetails[0]?.property?.zipCode
            .toLowerCase()
            .includes(searchValue) ||
          String(data?.refundAmount).includes(searchValue) ||
          String(data?.totalAmount).includes(searchValue) ||
          String(data?.paidToContractor).includes(searchValue)
      );
      setFilteredProjects(filteredData);
      setsortedProjects(filteredData); // Now contains the filtered projects
    }
  }, [projects.data, searchValue]);

  console.log(projects.data, "companyyyyyyyyyyName");

  const [filterModalOpen, setFilterModalOpen] = React.useState(false);
  const [filters, setFilters] = React.useState({
    serviceTypes: [],
    city: [],
    state: [],
    status: [],
  });

  // Define function to update filters and apply them together with search
  const applyFilters = (selectedFilters) => {
    console.log("Applying filters:", selectedFilters);
    setFilters(selectedFilters);
    // updateFilteredProjects(searchValue, selectedFilters); // no need since there is useEffect hook already for this (241 line)
  };

  const { projectDetail, listofCompanies } = useSelector(
    (store) => store.project
  );
  // Function to handle search and filter simultaneously
  const updateFilteredProjects = (search, currentFilters) => {
    const searchFiltered = projects.data?.filter(
      (project) =>
        project.name?.toLowerCase().includes(search) ||
        project.uniqueOrderID?.toString().toLowerCase().includes(search) ||
        project.company?.toLowerCase().includes(search) ||
        project.orderStatus.toLowerCase().includes(search) ||
        project.orderdetails[0]?.serviceName.toLowerCase().includes(search) ||
        project?.orderdetails[0]?.property?.city
          .toLowerCase()
          .includes(search) ||
        project?.orderdetails[0]?.property?.state
          .toLowerCase()
          .includes(search) ||
        project?.orderdetails[0]?.property?.zipCode
          .toLowerCase()
          .includes(search) ||
        String(project?.totalAmount).includes(search) ||
        String(project?.refundAmount).includes(search) ||
        String(project?.paidToContractor).includes(search)
    );

    if (!searchFiltered) return;

    const allObjKeysMap = {
      status: "orderStatus",
      city: "orderdetails.0.property.city",
      state: "orderdetails.0.property.state",
      servicetypes: "orderdetails.0.serviceName",
    };

    console.log(Object.keys(allObjKeysMap));
    const normalizedFilters = {};
    for (const [key, values] of Object.entries(currentFilters)) {
      let normalizedKey = key.toLowerCase();
      normalizedFilters[normalizedKey] = values;
    }
    const fullyFiltered = searchFiltered?.filter((project) => {
      return Object.entries(normalizedFilters).every(([key, values]) => {
        if (!values.size) return true;

        // using case insensitive search to avoid casing mismatch issues
        if (!Object.keys(allObjKeysMap).includes(key)) {
          console.log(`project is missing the key ${key}`);
          return false;
        }

        return Array.from(values).some((filterValue) =>
          String(getNestedProperty(project, allObjKeysMap[key]))
            .toLowerCase()
            .includes(filterValue.toLowerCase())
        );
      });
    });

    console.log(fullyFiltered, currentFilters);
    const updatedSortedProjects = sortProjects(fullyFiltered, sortConfig);
    setFilteredProjects(fullyFiltered);
    setsortedProjects(updatedSortedProjects);
  };

  React.useEffect(() => {
    if (filteredProjects?.length) {
      const updatedSortedProjects = sortProjects(filteredProjects, sortConfig);
      setsortedProjects(updatedSortedProjects);
    }
  }, [filteredProjects, sortConfig]);
  // Watch for changes in search or filters
  React.useEffect(() => {
    updateFilteredProjects(searchValue?.toLowerCase(), filters);
  }, [searchValue, filters, projects.data]);

  const breadcrumbs = [
    <Typography
      key="3"
      color="text.primary"
      style={{
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "34px",
        lineHeight: "36px",
        color: "#000000",
      }}
    >
      Projects
    </Typography>,
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleFilterClicked = (data) => {
    setSelectedFilter(data);
    if (data === "ALL") {
      const filters = {
        city: new Set(),
        servicetypes: new Set(),
        state: new Set(),
        status: new Set(projects?.data?.map((project) => project?.orderStatus)),
      };
      setFilters(filters);
    } else {
      const filters = {
        city: new Set(),
        servicetypes: new Set(),
        state: new Set(),
        status: new Set([data]),
      };
      setFilters(filters);
    }
  };

  useEffect(() => {
    dispatch(getProjects());
    dispatch(projectResponseClr(false));
  }, []);

  const handleSearch = (searchedValue) => {
    setSearchValue(searchedValue);
    setPage(0);
    updateFilteredProjects(searchedValue?.toLowerCase(), filters);
  };
  // const SortButton = styled(Button)(({ theme }) => ({
  //   // Add your styles here to match the button in the image
  //   margin: theme.spacing(2),
  //   padding: theme.spacing(1),
  // }));

  // Example usage in your column rendering logic
  // const userIds = projects?.data?assignedOrders.map(order => order.userTo._id).join(", ");
  // console.log(userIds);

  const getCsvData = () => {
    console.log("0000000000000000 ", sortedProjects, projects);
    if (sortedProjects != undefined && sortedProjects?.length > 0) {
      console.log("1111111111111 ", sortedProjects, projects);
      return sortedProjects?.map((project) => {
        // Flatten the project data
        const flattenedProject = {
          ProjectID: project?.uniqueOrderID,
          Customer: project?.name,
          "Service Type":
            project?.orderdetails && project?.orderdetails?.length > 0
              ? project.orderdetails[0]?.serviceName
              : "",
          // Assume 'Company' and other direct properties are top-level in the project object
          Company: project?.company,
          "Workers Assigned": project?.workersAssigned,
          City:
            project?.orderdetails &&
            project?.orderdetails?.length > 0 &&
            project?.orderdetails[0].property
              ? project?.orderdetails[0]?.property?.city
              : "",
          State:
            project?.orderdetails &&
            project?.orderdetails?.length > 0 &&
            project?.orderdetails[0]?.property
              ? project?.orderdetails[0]?.property?.state
              : "",
          Zip:
            project?.orderdetails &&
            project?.orderdetails?.length > 0 &&
            project?.orderdetails[0]?.property
              ? project?.orderdetails[0]?.property?.zipCode
              : "",
          // Format the first date in 'dateSelection' if it exists
          "Date Ordered":
            project?.dateSelection && project?.dateSelection?.length > 0
              ? moment(project?.dateSelection[0])?.format("MM/DD/YYYY")
              : "",
          // Assume 'DateScheduled' and 'DateCompleted' are in the same format as 'DateOrdered'
          "Date Scheduled": project?.dateScheduled,
          "Date Completed": project?.dateCompleted,
          // If 'Total Paid by Customer' is a direct property
          "Total Paid by Customer": project?.totalAmount,
          // Assume 'RefundToCustomer' and 'PaidToContractor' are direct properties
          "Refund To Customer": project?.refundAmount,
          "Paid To Contractor": project?.paidToContractor,
          Status: project?.orderStatus,
          "Last Status Update": project?.updatedAt
            ? moment(project?.updatedAt).format("MM/DD/YYYY")
            : "",
        };

        return flattenedProject;
      });
    }
  };

  console.log("yessssssss: ", isLoading);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="page-section">
      <Sidebar />
      <Box
        className="page-content"
        sx={{
          width: isDrawerOpen ? `calc(100% - 240px)` : `calc(100% - 57px)`,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
            // height: "7%",
          }}
        >
          <BeardcrumNavigator
            breadcrumbs={breadcrumbs ? breadcrumbs : "Beardcrums"}
          />
          <div>
            <Box
              component="div"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <CSVLink
                data={getCsvData() || ""}
                headers={columns.map((col) => ({
                  label: col.label,
                  key: col.label,
                }))}
                filename="projects_export.csv"
              >
                <Button
                  variant="outlined"
                  className="bc-btn-outline"
                  color="primary"
                >
                  Export csv
                </Button>
              </CSVLink>
            </Box>
            <br />
            {/* To Filter  */}
            {/* <Box
              component="div"
              sx={{
                alignItems: "center",
                justifyContent: "flex-end",
                display: "flex",
                gap: "1rem",
              }}
            >
              <Button
                variant="outlined"
                className="bc-btn-outline"
                color="primary"
                onClick={() => setFilterModalOpen(true)}
              >
                Filter
              </Button>
            </Box> */}
          </div>
        </Box>
        <Box
          component="div"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Box
            component="div"
            sx={{
              marginLeft: "1%",
              marginBottom: "2%",
              display: "flex",
              width: "100%",
            }}
          >
            {/* <SearchBox/> */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                width: "50%",
              }}
            >
              <Box
                sx={{
                  background: "#FFFFFF",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  borderRadius: "33px",
                  height: 50,
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "-2px",
                  maxWidth: "208px",
                  maxHeight: "30px",
                  border: "1px solid #ddd",
                  overflow: "hidden",
                  mt: 2,
                }}
              >
                <SearchRoundedIcon
                  sx={{
                    width: "16%",
                    marginLeft: "6px",
                  }}
                />
                <input
                  type="text"
                  value={searchValue}
                  // placeholder="Search"
                  className="search-input"
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </Box>
            </Box>

            {/* To Filter  */}
            <Box
              component="div"
              sx={{
                alignItems: "center",
                justifyContent: "flex-end",
                display: "flex",
                gap: "1rem",
                width: "50%",
                // backgroundColor: "palegoldenrod",
              }}
            >
              <Button
                variant="outlined"
                className="bc-btn-outline"
                color="primary"
                onClick={() => setFilterModalOpen(true)}
              >
                Filter
              </Button>
            </Box>
          </Box>
          {/* <SortButton
            startIcon={
              <IconButton aria-label='filter-icon'>
                <FilterListIcon />
              </IconButton>
            }
            onClick={sortLastLogin}
          ></SortButton> */}
        </Box>
        <Box
          component="div"
          sx={{
            marginTop: "-1.5%",
          }}
        >
          {tabFilters?.map((data) => (
            <span
              key={data}
              className="tabs-styling"
              style={{
                borderBottom: `${
                  selectedFilter == data
                    ? "1px solid #72777f"
                    : "1px solid #e0e0e0"
                }`,
                fontWeight: `${selectedFilter == data ? "bold" : ""}`,
                cursor: "pointer",
              }}
              onClick={() => handleFilterClicked(data)}
            >
              {data}
            </span>
          ))}
        </Box>
        <Paper sx={{ width: "100%", overflow: "hidden", marginTop: 3 }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table" sx={{}}>
              <TableHead>
                <StyledTableRow>
                  {columns.map((column) => (
                    <StyledTableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        fontWeight: column.fontWeight,
                      }}
                    >
                      {column.id === "lastStatusUpdate" ? (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => requestSort("lastStatusUpdate")}
                        >
                          Last Status Update
                          {sortConfig.key === "lastStatusUpdate" ? (
                            sortConfig.direction === "ascending" ? (
                              <NorthIcon fontSize="small" />
                            ) : (
                              <ArrowDownwardIcon fontSize="small" />
                            )
                          ) : (
                            <ArrowDownwardIcon fontSize="small" />
                          )}
                        </Box>
                      ) : (
                        column.label
                      )}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              </TableHead>

              <TableBody>
                {sortedProjects
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

                  .map((row) => {
                    console.log("Row data:", row);
                    console.log("orderrrrrrrrrrrrr:", row.orderdetails);
                    return (
                      <StyledTableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={parseInt(Date.now() * Math.random())}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <StyledTableCell
                            key={column.id}
                            align={column.align}
                            style={{ textTransform: column.textTransform }}
                          >
                            {column.id === "uniqueOrderID" ? (
                              <TableLink text={value} route={row._id} />
                            ) : column.id === "name" ? (
                              <TableLink text={value} route={`/customers/` + row.user} />
                            ) : column.id === "serviceType" ? (
                              row.serviceTitle || "N/A" // Access serviceTitle from the parent row directly
                            ) : column.id === "city" ? (
                              row.orderdetails?.[0]?.property?.city || "N/A" // Fetch city from the first property object
                            ) : column.id === "state" ? (
                              row.orderdetails?.[0]?.property?.state || "N/A" // Fetch state from the first property object
                            ) : column.id === "zipCode" ? (
                              row.orderdetails?.[0]?.property?.zipCode || "N/A" // Fetch zipCode from the first property object
                            ) : column.id === "company" ? (
                              row.assignedOrders && row.assignedOrders.length > 0 ? (
                                row.assignedOrders.map((assignedOrder, index) =>
                                  assignedOrder.companyDetails && index === 0 ? (
                                    <TableLink
                                      key={index}
                                      text={
                                        assignedOrder.companyDetails.companyName || "N/A"
                                      }
                                      route={`/companies/${assignedOrder.companyDetails._id}`} // Adjust the route as needed
                                    />
                                  ) : (
                                    index === 0 && "N/A"
                                  )
                                )
                              ) : (
                                "N/A"
                              )
                            ) : column.id === "workersAssigned" ? (
                              row.assignedOrders && row.assignedOrders.length > 0 ? (
                                row.assignedOrders[0]?.companyDetails &&
                                row.assignedOrders[0]?.companyDetails._id ? (
                                  row.assignedOrders.filter((order) => order.userTo).length
                                ) : (
                                  "0"
                                )
                              ) : (
                                "0"
                              )
                            ) : column.id === "dateOrdered" ? (
                              row.createdAt && moment(row.createdAt).format("MM/DD/YYYY")
                            ) : column.id === "dateScheduled" ? (
                              row.dateScheduled ? (
                                moment(row.dateScheduled).format("MM/DD/YYYY")
                              ) : (
                                "--" // Show -- if dateScheduled is not available
                              )
                            ) : column.id === "dateCompleted" ? (
                              row.dateCompleted ? (
                                moment(row.dateCompleted).format("MM/DD/YYYY")
                              ) : (
                                "--" // Show -- if dateCompleted is not available
                              )
                            ) : column.id === "totalPaidByCustomer" ? (
                              row.stripeRefundId ? (
                                "$" + row.refundAmount.toFixed(2)
                              ) : (
                                "$0.00"
                              )
                            ) : column.id === "refundToCustomer" ? (
                              row.stripeRefundId ? (
                                "$" + row.refundAmount.toFixed(2)
                              ) : (
                                "$0.00"
                              )
                            ) : column.id === "status" ? (
                              row.orderStatus
                            ) : column.id === "lastStatusUpdate" ? (
                              moment(row.updatedAt).format("MM/DD/YY hh:mm:ss A")
                            ) : column.format && typeof value === "number" ? (
                              column.format(value)
                            ) : (
                              value
                            )}
                          </StyledTableCell>
                          
                          
                          );
                        })}
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={filteredProjects?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      <DynamicFilter
        open={filterModalOpen}
        onClose={() => setFilterModalOpen(false)}
        filters={{
          servicetypes: [
            ...new Set(
              projects?.data?.map(
                (project) => project?.orderdetails[0]?.serviceName
              )
            ),
          ],
          city: [
            ...new Set(
              projects?.data?.map(
                (project) => project?.orderdetails[0]?.property?.city
              )
            ),
          ],
          state: [
            ...new Set(
              projects?.data?.map(
                (project) => project?.orderdetails[0]?.property?.state
              )
            ),
          ],
          status: [
            ...new Set(projects?.data?.map((project) => project?.orderStatus)),
          ],
        }}
        filterHeader={["serviceTypes", "city", "state", "status"]}
        applyFilters={applyFilters} // not being used in Dynamic Filter
        onApply={applyFilters} // Make sure this function is defined and passed here
      />
    </div>
  );
};

export default Projects;
