import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
} from "@mui/material";
import BeardcrumNavigator from "../../components/BeardcrumNavigator";
import Sidebar from "../../components/Sidebar";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loading from "../../components/Loading";
import { getCutomers } from "../../features/customer/customerSlice";

const Customers = () => {
  const { isDrawerOpen } = useSelector((store) => store.login);
  const dispatch = useDispatch();

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const [selectedFilters, setSelectedFilters] = useState({});
  const [searchValue, setSearchValue] = useState(""); // Allow any case (uppercase or lowercase)
  const [sortedCustomers, setSortedCustomers] = useState([]);

  const { isLoading, customers } = useSelector((store) => store.customer);

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  // Custom overlay component to display when no rows match
  const CustomNoRowsOverlay = () => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: "18px",
      }}
    >
      No matching records found
    </Box>
  );

  // Function to apply search and filters
  const applyFilters = (customersData) => {
    if (!customersData) {
      return [];
    }

    const normalizedSearchValue = searchValue.trim().toLowerCase(); // Convert search value to lowercase for case-insensitive comparison

    const normalizedFilters = Object.entries(selectedFilters).reduce(
      (acc, [key, values]) => {
        const normalizedKey =
          key.toLowerCase() === "phone" ? "phoneNumber" : key.toLowerCase();
        acc[normalizedKey] = values;
        return acc;
      },
      {}
    );

    return customersData.filter((customer) => {
      const fullName = `${customer.firstName || ""} ${
        customer.lastName || ""
      }`.toLowerCase(); // Convert customer name to lowercase for case-insensitive comparison
      const email = customer.email ? customer.email.toLowerCase() : ""; // Case-insensitive email comparison
      const phoneNumber = customer.phoneNumber
        ? customer.phoneNumber.toLowerCase()
        : ""; // Case-insensitive phone number comparison

      // Check if search value matches name, email, or phone
      const searchMatch =
        fullName.includes(normalizedSearchValue) ||
        email.includes(normalizedSearchValue) ||
        phoneNumber.includes(normalizedSearchValue);

      // If search does not match, return false
      if (!searchMatch) {
        return false;
      }

      // Apply additional filters based on the selected filters
      return Object.entries(normalizedFilters).every(([key, values]) => {
        if (!values.size) return true; // If no filters are set for this key, pass

        if (key === "name") {
          return Array.from(values).some((filterValue) =>
            fullName.includes(filterValue.toLowerCase()) // Ensure case-insensitive filter match for names
          );
        }

        return Array.from(values).some((filterValue) =>
          String(customer[key] || "")
            .toLowerCase()
            .includes(filterValue.toLowerCase()) // Ensure case-insensitive filter match for other fields
        );
      });
    });
  };

  const columns = [
    {
      headerName: "Name",
      field: "firstName",
      filterable: true,
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <Link
          to={`/customers/` + params.row.id}
          className="tableLink"
          style={{
            whiteSpace: "normal",
            wordBreak: "break-word",
            display: "inline-block",
          }}
        >
          {params.row.firstName + " " + params.row.lastName}
        </Link>
      ),
    },
    {
      headerName: "Email",
      field: "email",
      filterable: true,
      minWidth: 220,
      flex: 1,
    },
    {
      headerName: "Phone",
      field: "phoneNumber",
      filterable: true,
      minWidth: 150,
      flex: 1,
    },
    {
      field: "numberOfProperties",
      headerName: "Number of Properties",
      filterable: true,
      minWidth: 150,
      flex: 1,
    },
    {
      field: "numberOfOpenProjects",
      headerName: "Open Projects",
      filterable: true,
      minWidth: 130,
      flex: 1,
    },
    {
      field: "numberOfCompletedProjects",
      headerName: "Completed Projects",
      filterable: true,
      minWidth: 160,
      flex: 1,
    },
    {
      field: "totalPurchases",
      headerName: "Total Purchases",
      filterable: true,
      minWidth: 200,
      flex: 1,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
          ${params.row.totalPurchases.toFixed(2)}
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      filterable: true,
      minWidth: 130,
      flex: 1,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
          {Boolean(params.row.statusBit) === true ? "Enabled" : "Disabled"}
        </div>
      ),
    },
    {
      field: "lastLoginAt",
      headerName: "Last Active",
      filterable: true,
      minWidth: 350,
      flex: 1,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
          {new Intl.DateTimeFormat("en-US", {
            timeZone: "America/Los_Angeles",
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: true,
            timeZoneName: "short",
          })
            .format(new Date(params.row.lastLoginAt))
            .replace(/,\s/g, " ")
            .replace(/\s(AM|PM)/, "$1")
            .replace(/PST|PDT/g, "PT")}
        </div>
      ),
    },
  ];

  const breadcrumbs = [
    <Typography
      key="3"
      color="text.primary"
      style={{
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "34px",
        lineHeight: "36px",
        color: "#000000",
      }}
    >
      Customers
    </Typography>,
  ];

  const [page, setPage] = useState(0);

  useEffect(() => {
    dispatch(getCutomers());
  }, [dispatch]);

  useEffect(() => {
    if (customers?.data) {
      const filtered = applyFilters(customers.data);
      setSortedCustomers(filtered);
    }
  }, [customers, selectedFilters, searchValue]);

  const handleSearch = (searchedValue) => {
    setSearchValue(searchedValue); // Do not restrict uppercase letters in search box
    setPage(0);

    if (customers?.data) {
      const filteredCustomers = applyFilters(customers.data);
      setSortedCustomers(filteredCustomers);
    }
  };

  if (isLoading || !sortedCustomers) {
    return <Loading />;
  }

  return (
    <div className="page-section">
      <Sidebar />
      <Box
        className="page-content"
        sx={{
          width: isDrawerOpen ? `calc(100% - 240px)` : `calc(100% - 57px)`,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
          }}
        >
          <BeardcrumNavigator
            breadcrumbs={breadcrumbs ? breadcrumbs : "Beardcrums"}
          />
        </Box>
        <Box
          component="div"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Box
            component="div"
            sx={{
              marginLeft: "2%",
            }}
          >
            <Box
              sx={{
                background: "#FFFFFF",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "33px",
                height: 50,
                display: "flex",
                alignItems: "center",
                maxWidth: "208px",
                maxHeight: "30px",
                border: "1px solid #ddd",
                overflow: "hidden",
              }}
            >
              <SearchRoundedIcon
                sx={{
                  width: "16%",
                  marginLeft: "6px",
                }}
              />
              <input
                type="text"
                value={searchValue}
                className="search-input"
                onChange={(e) => handleSearch(e.target.value)}
              />
            </Box>
          </Box>
        </Box>

        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <Box
            sx={{
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#e0e0e0",
                color: "black",
                display: "flex!important ",
                flexDirection: "row !important ",
              },
              "& .MuiDataGrid-columnHeader": {
                backgroundColor: "#e0e0e0",
                color: "black",
                display: "flex!important ",
                flexDirection: "row !important ",
              },
              "& .MuiDataGrid-row": {
                backgroundColor: "white",
              },
              "& .MuiDataGrid-row:nth-of-type(odd)": {
                backgroundColor: "#f5f5f5",
              },
            }}
          >
            <DataGrid
              autoHeight
              columns={columns}
              rows={sortedCustomers || []}
              rowHeight={70}
              slots={{
                toolbar: GridToolbar,
                noRowsOverlay: CustomNoRowsOverlay,
              }}
            />
          </Box>
        </Paper>
      </Box>
    </div>
  );
};

export default Customers;
