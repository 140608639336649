import {
  Box,
  Table,
  TableContainer,
  Typography,
  IconButton,
  Button,
  ButtonGroup,
} from "@mui/material";
import React, { useEffect } from "react";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import AddUserModal from "./AddUserModal";
import EditUserModal from "./EditUserModal";
import { useDispatch, useSelector } from "react-redux";
import { handleEditUserModal } from "../../features/login/loginSlice";
import {
  getUsers,
  userInfoResponseClr,
} from "../../features/userInfo/userInfoSlice";
import { useParams } from "react-router-dom";
import moment from "moment";
import Loading from "../../components/Loading";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormGroup,
  FormControlLabel,
  Checkbox,
  DialogActions,
} from "@mui/material";
// user info
const userInfoColumns = [
  { id: "firstName", label: "Name", minWidth: 150, fontWeight: "600" },
  { id: "email", label: "Email", minWidth: 150, fontWeight: "600" },
  { id: "phoneNumber", label: "Phone", minWidth: 150, fontWeight: "600" },
  { id: "statusBit", label: "Status", minWidth: 80, fontWeight: "600" },
  {
    id: "approvedByReli",
    label: "Approved by Reli",
    minWidth: 120,
    fontWeight: "600",
    textTransform: "capitalize",
  },
  {
    id: "accountType",
    label: "Type",
    minWidth: 100,
    fontWeight: "600",
    textTransform: "capitalize",
  },
  { id: "updatedAt", label: "Last Active", minWidth: 200, fontWeight: "600" },
  // { id: 'actions', label: 'Actions', minWidth: 130, fontWeight: '600' },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.gray,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
    backgroundColor: "#ddd",
  },
}));

const UserInfo = () => {
  const dispatch = useDispatch();
  const param = useParams();
  const { isAddUserModal, isEditUserModal } = useSelector(
    (store) => store.login
  );
  const { alert, users, isLoading, responseStatus, responseMsg, isUpdated } =
    useSelector((store) => store.userInfo);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [alertDialog, setAlertDialog] = React.useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [searchValue, setSearchValue] = React.useState("");
  const [filterModalOpen, setFilterModalOpen] = React.useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleSearch = (searchedValue) => {
    setSearchValue(searchedValue);
    // setPage(0);
  };

  useEffect(() => {
    dispatch(getUsers(param.companyid));
  }, [dispatch, param.companyid, isUpdated]);

  const [sortDirection, setSortDirection] = React.useState("desc"); // 'asc' for ascending, 'desc' for descending
  const [sortedUsers, setsortedUsers] = React.useState([]);

  const filteredUsers = sortedUsers.filter(
    (data) =>
      data.firstName?.toLowerCase().includes(searchValue) ||
      data.email?.toLowerCase().includes(searchValue) ||
      data.phone?.toLowerCase().includes(searchValue)
  );
  const [filters, setFilters] = useState({
    firstName: [],
    email: [],
    phoneNumber: [],
    approvedByReli: [],
    accountType: [],
    statusBit: [],
    updatedAt: [],
  });


  useEffect(() => {
    if (users?.data) {
      setsortedUsers(users.data);
      console.log("Users data:", users.data); 
    }
  }, [users?.data]);

  const applyFilters = (selectedFilters) => {
    console.log("Applying filters:", selectedFilters);
  
    const newFilteredData = users.data.filter((user) => {
      let isMatch = true;
  
      for (const category of filterHeader) {
        const selectedValues = Array.from(selectedFilters[category]);
  
        if (selectedValues.length > 0) {
          // Handle specific case for accountType
          const userValue =
            category === "accountType"
              ? user[category] === "admin_contractor"
                ? "admin_contractor"
                : "standard_contractor"
              : user[category];
  
          if (!selectedValues.includes(userValue)) {
            isMatch = false;
            break;
          }
        }
      }
  
      return isMatch;
    });
  
    console.log("Filtered data:", newFilteredData);
    return newFilteredData;
  };
  
  

  const filtersCompanies = {
    firstName: [
      ...new Set(users?.data?.map((item) => item.firstName).filter(Boolean)),
    ],
    email: [...new Set(users?.data?.map((item) => item.email).filter(Boolean))],
    phoneNumber: [
      ...new Set(users?.data?.map((item) => item.phoneNumber).filter(Boolean)),
    ],
    accountType: [
      ...new Set(users?.data?.map((item) => item.role).filter(Boolean)),
    ],
    approvedByReli: [
      ...new Set(
        users?.data?.map((item) => item.approvedByReli).filter(Boolean)
      ),
    ],
    statusBit: [true, false], 

    updatedAt: [
      ...new Set(users?.data?.map((item) => item.updatedAt).filter(Boolean)),
    ],
  };
  const filterHeader = [
    "firstName",
    "email",
    "phoneNumber",
    "accountType",
    "approvedByReli",
    "statusBit",
    "updatedAt",
  ];

  const [selectedFilters, setSelectedFilters] = useState(() => {
    const initialFilters = {};
    filterHeader.forEach((category) => {
      initialFilters[category] = new Set(); 
    });
    return initialFilters;
  });

  const convertString = (input) => {
    // Split the input string based on uppercase letters
    const splitWords = input.replace(/([A-Z])/g, " $1").trim();

    // Capitalize the first letter of each word
    const capitalizedWords = splitWords
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");

    return capitalizedWords;
  };

  const handleSubmit = () => {
    setFilters(selectedFilters);
    setFilterModalOpen(false);
    const newFilteredData = applyFilters(selectedFilters);
    setsortedUsers(newFilteredData);
  };

  const handleToggle = (category, value) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = {
        ...prevFilters,
        [category]: new Set(prevFilters[category]),
      };

      if (updatedFilters[category].has(value)) {
        updatedFilters[category].delete(value);
      } else {
        updatedFilters[category].add(value);
      }

      console.log(updatedFilters, "Updated filters after toggle");
      return updatedFilters;
    });
  };

  useEffect(() => {
    if (users?.data) {
      setsortedUsers(users.data);
    }
  }, [users?.data]);

  if (isLoading) {
    return <Loading />;
  }

  console.log("Filter Companies: ", filtersCompanies)
  console.log("Users: ", users)
  console.log("columnnnn" , userInfoColumns)
  return (
    <>
      <Box
        component="div"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Box component="div">
          {/* <SearchBox/> */}
          <Box
              sx={{
                background: "#FFFFFF",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "33px",
                height: 50,
                display: "flex",
                alignItems: "center",
                maxWidth: "208px",
                maxHeight: '30px',
                border: "1px solid #ddd",
                overflow: "hidden",
              }}
            >
              <SearchRoundedIcon
                sx={{
                  width: "16%",
                  marginLeft: "6px",
                }}
              />
              <input
                type="text"
                value={searchValue}
                // placeholder="Search"
                className="search-input"
                onChange={(e) => handleSearch(e.target.value.toLowerCase())}
              />
            </Box>
        </Box>
        <Box
          component="div"
          sx={{
            //  display: 'flex',
            alignItems: "center",
            gap: "1rem",
            float: "right",
          }}
        >
          <Button
            variant="outlined"
            className="bc-btn-outline"
            color="primary"
            onClick={() => setFilterModalOpen(true)}
          >
            Filter
          </Button>
        </Box>
      </Box>

      {alert ? (
        <Alert
          severity={responseStatus}
          color={responseStatus}
          sx={{ mb: 3, width: "100%" }}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                dispatch(userInfoResponseClr(false));
                setAlertDialog(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {responseMsg}
        </Alert>
      ) : null}

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table" sx={{}}>
            <TableHead>
              <StyledTableRow>
                {userInfoColumns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      fontWeight: column.fontWeight,
                    }}
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {sortedUsers
                ?.filter(
                  (data) =>
                    data.firstName?.toLowerCase().includes(searchValue) ||
                    data.email?.toLowerCase().includes(searchValue) ||
                    data.phoneNumber?.toLowerCase().includes(searchValue)
                )
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {userInfoColumns.map((column) => {
                        const value = row[column.id];
                        console.log(value , "value is :")

                        return (
                          <StyledTableCell
                            key={column.id + "UserInfo"}
                            align={column.align}
                            style={{ textTransform: column.textTransform }}
                          >
                            {column.id === "statusBit" ? (

                              (() => {
                                const statusValue =
                                  value !== undefined ? value : "N/A";
                                console.log(statusValue , "status valueeeeeee")
                                return statusValue === true ||
                                  value === "true"
                                  ? "Enabled"
                                  : "Disabled";
                              })()
                            ) : column.id === "accountType" ? (
                              value === "admin_contractor" ? "Admin" : "Staff"
                            ) : column.id === "updatedAt" ? (
                              moment(value).format("MM/DD/YY hh:mm:ss A")
                            ) : column.id === "email" ? (
                              <Typography className="tableLink">
                                {value}
                              </Typography>
                            ) : column.id === "firstName" ? (
                              <Typography
                                className="tableLink"
                                onClick={() => {
                                  dispatch(handleEditUserModal());
                                  setSelectedUserId(row._id);
                                }}
                              >
                                {value}
                              </Typography>
                            ) : (
                              value
                            )}
                          </StyledTableCell>
                        );
                      })}
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filteredUsers.length} // This should be the length of the filtered array
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {isAddUserModal ? <AddUserModal /> : null}
      {isEditUserModal ? <EditUserModal userId={selectedUserId} /> : null}

      {filterModalOpen && (
        <Dialog
          open={filterModalOpen}
          onClose={() => setFilterModalOpen(false)}
        >
          <DialogTitle>Filter Services</DialogTitle>
          <DialogContent>
            {filterHeader.map((category) => (
              <FormGroup key={category}>
                <Typography variant="subtitle1" fontWeight="bold">
                  {category === "accountType"
                    ? "Type"
                    : category === "statusBit"
                    ? "Status"
                    : convertString(category)}
                </Typography>
                {filtersCompanies[category]?.map((value) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedFilters[category]?.has(value) || false}
                        onChange={() => handleToggle(category, value)}
                      />
                    }
                    label={
                      category === "accountType"
                        ? value === "admin_contractor" 
                          ? "Admin" 
                          : "Staff"
                        : category === "statusBit"
                        ? value === true || value === "true" || value === "enabled" || value === "enable" 
                          ? "Enabled"
                          : "Disabled"
                        : value
                    }
                    key={value}
                  />
                ))}
              </FormGroup>
            ))}
          </DialogContent>

          <DialogActions>
            <Button
                onClick={() => {
                    setFilterModalOpen(false);
                    setSelectedFilters(() => {
                        const initialFilters={}
                        filterHeader.forEach((category) => {
                           initialFilters[category]=new Set();
                        });
                        return initialFilters;
                    });
                }}
            >
                Cancel
            </Button>
            <Button onClick={handleSubmit}>Apply</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default UserInfo;
