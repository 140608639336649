import { Box, Button, Card, Grid, IconButton, Typography, TextField, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Switch } from '@mui/material';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import BeardcrumNavigator from '../../components/BeardcrumNavigator';
import Sidebar from '../../components/Sidebar';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useRef } from 'react';
import { AboutCard} from '../../components/styles/ActivityBox.styles'
import { Field, Form, Formik, useFormik } from 'formik';
import { object } from 'yup';
import * as Yup from "yup"
import {  getCouponDetail, updateCoupon } from '../../features/coupons/couponsSlice';
import Loading from '../../components/Loading';
import { useParams } from 'react-router';
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";

import { useNavigate } from 'react-router-dom';
import ActivityLog from '../../components/ActivityLog';

const CouponCard = styled(Card)(({theme}) => ({
  background: '#F7F7F7',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '16px',
  gap: '16px'
}))
const CouponInnerBox = styled(Box)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '16px',
  gap: '11px',
  background: '#E8E8E8',
  boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
  [theme.breakpoints.down('md')] : {
    width: '100%',
  },
  [theme.breakpoints.up('md')] : {
    flex: '1'
  },
}))
const CouponButton = styled(Button)(({theme}) => ({
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
  [theme.breakpoints.up('md')]: {
    width: '345px',
  },
}))


const EditCoupon = () => {
const dispatch = useDispatch();
const { couponid } = useParams();
const { isDrawerOpen } = useSelector((store) => store.login);
const { detail, isLoading } = useSelector((store) => store.coupon);

const navigate = useNavigate();

const goBack = () => {
  navigate(-1);
};
useEffect(() => {
  dispatch(getCouponDetail(couponid));
}, [couponid, dispatch]);
const [switchToggled, setSwitchToggled] = useState(detail?.data?.statusBit);
const [originalState, setOriginalState] = useState(detail?.data?.statusBit);

useEffect(() => {
  setSwitchToggled(detail?.data?.statusBit);
  setOriginalState(detail?.data?.statusBit);
}, [detail]);

useEffect(() => {
  if (switchToggled !== originalState) {
    setIsSubmitting(false);
  }
}, [switchToggled, originalState]);

const breadcrumbs = [
    <Typography key="3" color="text.primary" style={{
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '34px',
        lineHeight: '36px',
        color: '#000000'
    }}>
       <Button
        color="inherit"
        sx={{
         
          right: "5px",
          bottom: "0",
          height: "50px",
          minWidth: "50px !important",
          borderRadius: "50%",
          padding: "0",
          "&:hover": {},
        }}
        onClick={goBack}
      >
      <ArrowBackIosRoundedIcon  />

      </Button>
        
        Coupon Details
    </Typography>
];
const [isSubmitting, setIsSubmitting] = useState(false);
const [isSwitch, SetIsSwitch] = useState('enabled')
useEffect(() => {
  setImgSrc(detail ? detail?.data?.image :"" )
  setOriginalState(detail?.data?.statusBit)
  if (detail?.data?.statusBit) {
    SetIsSwitch('enabled');
  } else {
    SetIsSwitch('disabled');
  }
}, [detail,dispatch]);

const handleSwitch = (event, checked) => {
  SetIsSwitch(checked ? 'enabled' : 'disabled');
  setSwitchToggled(!switchToggled);
};

let imgInput = useRef(null)
let imgRef = useRef(null)
const pickFile = () => {
    imgInput.current.click();
}
const [imgSrc, setImgSrc] = useState( detail?.data?.image ? detail?.data?.image : '/images/image.png'); // Default image

const handlePickImage = (event, setFieldValue) => {
  if (event.target.files && event.target.files[0]) {
    const file = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.onload = (e) => setImgSrc(e.target.result);
    fileReader.readAsDataURL(file);
    setFieldValue('image', file);  // Set the file to the Formik state
  } else {
    setImgSrc('');
    setFieldValue('image', null);  // Reset the image field in the Formik state
  }
};
const validationSchema = Yup.object({
  name: Yup.string().trim().required('*Please enter the name of the coupon').matches(/^\S.*$/, 'Whitespace is not allowed'),
  description: Yup.string().trim().required('*Please enter the description for the coupon').matches(/^\S.*$/, 'Whitespace is not allowed'),
  couponValue: Yup.string().trim().required('*Please input the value for this coupon').matches(/^\S.*$/, 'Whitespace is not allowed'),
  couponCode: Yup.string().trim().required('*Please enter the coupon code').matches(/^\S.*$/, 'Whitespace is not allowed'),
  // image: Yup.mixed().required('An image is required'), 
  // other fields validation
});
if (isLoading || !detail?.data) {
  return <Loading />;
}

  return (
    <div className="page-section">
      <Sidebar/>
      <Box className="page-content" sx={{width: isDrawerOpen ? `calc(100% - 240px)` : `calc(100% - 57px)`, overflow: "hidden" , maxWidth :"100%"}}>
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 3
            }}>
            <BeardcrumNavigator breadcrumbs={breadcrumbs ? breadcrumbs : "Beardcrums"}/>
        </Box>

        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'start',
          flexWrap: 'wrap',
          gap: '1rem'
        }}>
           <AboutCard>
          
        <Formik 
            initialValues={detail?.data}
            onSubmit={(values, formikHelpers) => {
              let updatedValues = {
                ...values,
                statusBit: isSwitch === 'enabled'
              };
              
              console.log('Updated values: ', updatedValues);
              
              setIsSubmitting(true);
              
              dispatch(updateCoupon(updatedValues))
                .then(() => {
                  console.log('Coupon updated successfully.');

                  setSwitchToggled(!switchToggled);

                  dispatch(getCouponDetail(couponid));
                  
                })
                .catch(error => {
                  console.error('Failed to update coupon:', error);
                })
                .finally(() => {
                  setIsSubmitting(false); // Reset the submitting state
                  formikHelpers.resetForm();
                });
              
      
            }}
          
                validationSchema= {
                  validationSchema}
              >
              {({errors, touched, isValid, dirty,setFieldValue}) => (
                <Form>
                  
                  <CouponCard>
                    <Typography sx={{display: 'block', fontSize: '34px', width: '100%'}} variant="h3" >About</Typography>
                
                      <Box sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'flex-start',
                        gap: '1rem',
                        width: '100%'
                      }}>
                        <CouponInnerBox>
                          <Field as={TextField} 
                            sx={{width: '100%'}}
                            id="name"
                            name="name"
                            label="Coupon Name"
                            variant="outlined"
                            error = {Boolean(errors.name) && Boolean(touched.name)}
                            helperText = {Boolean(touched.name) && errors.name}
                          />
                          <Field as={TextField} sx={{
                            width: '100%',
                            mt: 3,
                            mb: 3
                          }}
                            id="description"
                            name="description"
                            label="Coupon Description"
                            multiline
                            rows={10}
                            variant="outlined"
                            error = {Boolean(errors.description) && Boolean(touched.description)}
                            helperText = {Boolean(touched.description) && errors.description}
                          />
                             <Field as={TextField} 
                            sx={{width: '100%',    mb: 3}}
                            id="couponValue"
                            name="couponValue"
                            label="Coupon Value (%)"
                            variant="outlined"
                     
                            error = {Boolean(errors.couponValue) && Boolean(touched.couponValue)}
                            helperText = {Boolean(touched.code) && errors.code}
                          />
                          <FormControl fullWidth sx={{mb: 3}}>
                            <InputLabel id="service">Service</InputLabel>
                            <Field as={Select}
                              labelId="service"
                              id="service"
                              name="service"
                              label="Service"
                            >
                              <MenuItem value='Windows'>Windows</MenuItem>
                              <MenuItem value="Sliding Glass Doors">Sliging Glass Doors</MenuItem>
                              <MenuItem value="Doors">Interior Doors</MenuItem>
                            </Field>
                          </FormControl>
                          <Field as={FormControlLabel}
                          sx={{
                            fontSize: '16px',
                            letterSpacing: '1.25px',
                            color: '#979797'
                          }}
                          name="statusBit"
                          value={isSwitch}
                          control={
                            <Switch 
                              color="primary"
                              checked={isSwitch === 'enabled'}
                              onChange={handleSwitch} // Just pass the handler function here
                            />
                          }
                          label={isSwitch === 'enabled' ? 'Enabled' : 'Disabled'}
                          labelPlacement="start"
                        />
                          <Field as={TextField} 
                            sx={{width: '100%'}}
                            id="couponCode"
                            name="couponCode"
                            label="Coupon Code"
                            variant="outlined"
                            error = {Boolean(errors.couponCode) && Boolean(touched.couponCode)}
                            // helperText = {Boolean(touched.code) && errors.code}
                          />
                          
                        </CouponInnerBox>
                        <CouponInnerBox sx={{alignItems: 'center', minHeight: '482px', justifyContent: 'center'}}>

                          <Box>
                          <img src={imgSrc} alt="Upload preview" ref={imgRef} style={{ width: '100%' }} />

                          </Box>
                
                          <Button variant="contained" startIcon={<AddRoundedIcon/>} onClick={pickFile}>
                            Upload
                          </Button>

                          <input type="file" hidden ref={imgInput} onChange={(event) => handlePickImage(event, setFieldValue)} />
                          <Typography sx={{fontSize: '12px'}}>
                            Recommended image size is 900x450 px <br/>
                            Image type should be .jpeg <br/>
                            Corners will be trimmed on display with 10pt radius
                          </Typography>
                        </CouponInnerBox>
                      
                      </Box>

                      <CouponButton
                        // disabled={!((dirty || switchToggled) && isValid && !isSubmitting)}
                        disabled={!(dirty || switchToggled !== originalState) || !isValid || isSubmitting}
                        type='submit'
                        variant='contained'
                      >
                        {isSubmitting ? "Saving..." : "Save"}
                      </CouponButton>


                  </CouponCard>
                </Form>
              )}
            </Formik>
          </AboutCard>
          <ActivityLog/>
        </Box>
      </Box>
    </div>  
  )
}

export default EditCoupon